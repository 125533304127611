import CompareList from './compare/CompareList';

window.addEventListener('load', () => {
  const element = document.querySelector('[data-customer-compare]');
  if (element) {
    const compareList = new CompareList(element, {
      by() {
        // Если меняешь тут - проверь соответствие с _settings.scss
        if (window.innerWidth < 767) {
          return 2;
        }
        if (window.innerWidth < 1259) {
          return 3;
        }
        return 4;
      },
    });

    window.addEventListener('resize', () => {
      compareList.render();
    });

    document.addEventListener('compareToggle', (e) => {
      if (e.detail.linkElement && !e.detail.inCompare) {
        const link = e.detail.linkElement;
        const blockElement = link.closest('[data-compare-block-element]');
        if (blockElement) {
          compareList.deleteElementByKey(blockElement.dataset.compareBlockElement);
        }
      }
    });
  }
});