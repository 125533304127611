import Jax from "../../components/jax/jax.js";
import IMask from "imask";

const initPhoneField = () => {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = "true";

    const mask = IMask(input, {
      mask: '+{7} (000) 000-00-00',

    });

    mask
      .on('accept', (e) => {
        const value = mask.unmaskedValue;
        if (value.slice(0, 2) === '78') {
          mask.unmaskedValue = `7${value.slice(2, value.length)}`;
          mask.updateValue();
        }
      })
      .on('complete', () => {
        if (input.dataset.leadUrl) {
          let jax = new Jax(input.dataset.leadUrl, 'POST');
          jax.send({
            phone: input.value,
          });
        }
      });
  });
}

document.addEventListener("DOMContentLoaded", initPhoneField);
document.addEventListener("DOMContentMutated", initPhoneField);