import { onDomChanges } from "../../components/dynamic/observer";
import { singleBind } from "../../components/dynamic/bind";
import axios from "axios";

onDomChanges(() => {
  function getColumns(tableName) {
    const columns = [];
    document.querySelectorAll(`[data-switcher-item="${tableName}"]`).forEach((element) => {
      if (element.checked) {
        columns.push(element.value);
      }
    });
    return columns;
  }

  async function setSettings(tableKey, columns) {
    return axios.get(window.location.href, {
      params: {
        tableKey,
        tableColumns: columns,
      },
    });
  }

  document.querySelectorAll('[data-switcher-item]').forEach((element) => {
    singleBind(element, 'toggle', 'change', () => {
      async function processEvent() {
        const tableKey = element.dataset.switcherItem;
        const columns = getColumns(tableKey);
        await setSettings(tableKey, columns);
        const event = new CustomEvent('table:switcher-toggle', {
          detail: {
            element,
            tableKey,
            columns,
          },
        });
        document.dispatchEvent(event);
      }
      processEvent();
    });
  });

  document.querySelectorAll('[data-switcher-apply]').forEach((element) => {
    singleBind(element, 'toggle', 'click', (e) => {
      e.preventDefault();
      async function processEvent() {
        const tableKey = element.dataset.switcherApply;
        const columns = getColumns(tableKey);
        await setSettings(tableKey, columns);
        const event = new CustomEvent('table:switcher-apply', {
          detail: {
            element,
            tableKey,
            columns,
          },
        });
        document.dispatchEvent(event);
      }
      processEvent();
    });
  });
});