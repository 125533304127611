import { tns } from 'tiny-slider/src/tiny-slider';
import updateFilling from './common/updateFilling';
import sliderCounter from './common/sliderCounter';
import bindArrows from './common/bindArrows';

class MyTinySlider {
  constructor(options) {
    this.options = {
      sliderContainer: '',
      sliderList: '',
      itemsCount: 1,
      slideIndex: '[data-slider-index]',
      slideCount: '[data-slider-count]',
      arrowLeft: '[data-slider-prev]',
      arrowRight: '[data-slider-next]',
      sliderInitialization: 'data-slider-initialization',
      slider: null,
      disableTabletDown: false,
      tabletWidth: 1280,
      mode: 'carousel',
      animateIn: 'fade-in',
      animateOut: 'fade-out',
      animateNormal: 'normal',
      hideShowArrows: false,
      autoHeight: false,
      edgePadding: 0,
      gutter: 0,
      speed: 700,
      navPosition: 'top',
      autoWidth: false,
      fixedWidth: false,
      verticalTable: false,
      fillingLine: false,
      autoplayButton: false,
      preventActionWhenRunning: false,
      onInit: false,
      autoplayTimeout: 5000,
      center: true,
      rewind: false,
      loop: false,
    };
    this.options = Object.assign({}, this.options, options);

    this.init();
  }

  init() {
    const sliderContainers = document.querySelectorAll(this.options.sliderContainer);
    if (sliderContainers) {
      sliderContainers.forEach((sliderContainer) => {
        if (sliderContainer.dataset.sliderInitialization) {
          return true;
        }
        sliderContainer.dataset.sliderInitialization = '1';

        const sliderList = sliderContainer.querySelector(this.options.sliderList);

        if (sliderList) {
          this.initSlider(sliderContainer, sliderList);
        }

        return true;
      });
    }
  }

  initSlider(sliderContainer, sliderList) {
    const options = {
      onInit: this.options.onInit,
      container: sliderList,
      controls: false,
      nav: this.options.nav,
      items: this.options.itemsCount,
      speed: this.options.speed,
      autoplay: this.options.autoplay,
      center: this.options.center,
      loop: this.options.loop,
      navPosition: this.options.navPosition,
      autoplayButtonOutput: false,
      autoplayButton: this.options.autoplayButton,
      mode: this.options.mode,
      rewind: this.options.rewind,
      animateIn: this.options.animateIn,
      animateOut: this.options.animateOut,
      animateNormal: this.options.animateNormal,
      autoHeight: this.options.autoHeight,
      edgePadding: this.options.edgePadding,
      gutter: this.options.gutter,
      autoWidth: this.options.autoWidth,
      fixedWidth: this.options.fixedWidth,
      fillingLine: this.options.fillingLine,
      preventActionWhenRunning: this.options.preventActionWhenRunning,
      autoplayTimeout: this.options.autoplayTimeout,
      responsive: {},
    };

    if (this.options.responsive) {
      options.responsive = Object.assign(options.responsive, this.options.responsive);
    }

    this.slider = tns(options);

    if (options.fillingLine) {
      this.slider.events.on('indexChanged', () => {
        updateFilling(this.slider.getInfo());
      });
      updateFilling(this.slider.getInfo());
    }

    sliderContainer.setAttribute(this.options.sliderInitialization, 'true');

    if (this.options.arrowLeft && this.options.arrowRight) {
      bindArrows(this.options.arrowLeft, this.options.arrowRight, this.slider, sliderContainer);
    }

    sliderCounter(sliderContainer, this.options.slideIndex, this.options.slideCount, this.slider);
  }
}

export default MyTinySlider;