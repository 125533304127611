export default class State {
  static set(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.log(e);
    }
  }

  static get(key, byDefault) {
    try {
      const value = localStorage.getItem(key);
      if (value === null) {
        return byDefault;
      }
      return JSON.parse(value);
    } catch (e) {
      console.log(e);
    }
  }
}