import Office from "./Office";
import defaultPlacemarkPath from '!file-loader!../../images/svg/office/placemark-default.svg';
import selectedPlacemarkPath from '!file-loader!../../images/svg/office/placemark-selected.svg';

export default class Offices {
  constructor(element) {
    this.rootElement = element;
    if (!element) {
      return;
    }
    this.settings = Object.assign({

    }, element.dataset);
    this.mapElement = element.querySelector('[data-offices-map]');
    this.listElement = element.querySelector('[data-offices-list]');
    this.bg = element.querySelector('[data-offices-bg]');
    this.map = undefined;
    this.offices = [];
    this.activeOffice = undefined;
    this.initMapPending();
    this.bindEvents();
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  bindEvents() {
    document.querySelectorAll('[data-offices-open-list]').forEach((link) => {
      link.addEventListener('click', () => {
        this.showListScreen();
      });
    });
    document.querySelectorAll('[data-offices-open-map]').forEach((link) => {
      link.addEventListener('click', () => {
        this.showMapScreen();
      });
    });
  }

  initMapPending() {
    if (window.ymaps) {
      this.initMap();
      return;
    }
    setTimeout(() => {
      this.initMapPending();
    }, 500);
  }

  initMap() {
    ymaps.ready(() => {
      this.initObjects();
      this.createMap();
    });
  }
  initObjects() {
    this.rootElement.querySelectorAll('[data-list-item]').forEach((listItemElement) => {
      const itemElement = this.rootElement.querySelector(`[data-item][data-id="${listItemElement.dataset.id}"]`);
      if (!itemElement) {
        console.error('Could not find item element for list item element', {
          listItemElement,
        });
        return;
      }

      const office = new Office(
        listItemElement,
        itemElement,
        this.bg,
      );
      const placemark = new ymaps.Placemark([office.getLat(), office.getLng()], {

      }, {
        iconLayout: 'default#imageWithContent',
        // Своё изображение иконки метки.
        iconImageHref: defaultPlacemarkPath,
        // Размеры метки.
        iconImageSize: [82, 82],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-41, -37],
        // Смещение слоя с содержимым относительно слоя с картинкой.
        iconContentOffset: [0, 0],
      });
      placemark.events.add('click', () => {
        this.onPlacemarkClick(office);
      });
      listItemElement.addEventListener('click', () => {
        this.onListElementClick(office);
      });
      office.setPlacemark(placemark);
      this.offices.push(office);
    });
  }

  createMap() {
    const rect = this.mapElement.getBoundingClientRect();
    let bottom = 220;
    if (rect.height) {
      bottom = (rect.height/2 - 30);
    }

    this.map = new ymaps.Map(this.mapElement, {
      center: [0, 0],
      zoom: 14,
      controls: ['zoomControl'],
    }, {
      zoomControlPosition: { right: 10, top: 'auto', left: 'auto', bottom: bottom + 'px' },
      zoomControlSize: 'small',
    });
    this.map.behaviors.disable('scrollZoom');

    this.map.margin.setDefaultMargin(this.getMapMargin());

    this.fitCenter();

    this.offices.forEach((office) => {
      this.map.geoObjects.add(office.getPlacemark());
    });
  }

  fitCenter() {
    // Берем все координаты от всех точек
    let minLat;
    let minLng;
    let maxLat;
    let maxLng;

    this.offices.forEach((office) => {
      if (minLat === undefined || minLat > office.getLat()) {
        minLat = office.getLat();
      }
      if (maxLat === undefined || maxLat < office.getLat()) {
        maxLat = office.getLat();
      }

      if (minLng === undefined || minLng > office.getLng()) {
        minLng = office.getLng();
      }
      if (maxLng === undefined || maxLng < office.getLng()) {
        maxLng = office.getLng();
      }
    });

    // Определяем центр и оптимальный зум
    ymaps.util.requireCenterAndZoom(
      this.map.getType(),
      [[minLat, minLng], [maxLat, maxLng]],
      this.map.container.getSize(),
      { margin: this.getMapMargin() },
    ).then((result) => {
      this.map.setCenter(result.center, result.zoom);
    });
  }

  getMapMargin() {
    if (this.isMobile()) {
      return [31, 31, 31, 31];
    }
    const bounds = this.listElement.getBoundingClientRect();
    return [62, 62, 62, bounds.right + 62];
  }

  moveMapCenterTo(coords) {
    if (this.isMobile()) {
      this.map.setCenter(coords, 16);
    } else {
      this.map.panTo(
        coords, {
          duration: 1500,
          flying: true,
          useMapMargin: true,
        },
      );
    }
  }

  refreshMap() {
    if (this.isMobile()) {
      this.map.container.fitToViewport();
    }
  }

  onPlacemarkClick(office) {
    this.activateOffice(office);
  }

  onListElementClick(office) {
    this.activateOffice(office);
  }

  deactivateOffice() {
    if (this.activeOffice) {
      // Возвращаем стандартную иконку
      this.activeOffice.getPlacemark().options.set('iconImageHref', defaultPlacemarkPath);
      // Убираем класс активности для item
      this.activeOffice.hide();
      this.activeOffice = null;
    }
  }

  activateOffice(office) {
    this.deactivateOffice();
    // Скрываем список
    // Отображаем нужный офис
    // Меняем плейсмарк на новом офисе

    office.getPlacemark().options.set('iconImageHref', selectedPlacemarkPath);
    office.show();
    this.showItemScreen();
    this.refreshMap();
    this.moveMapCenterTo([office.getLat(), office.getLng()]);
    this.activeOffice = office;
  }

  showItemScreen() {
    this.rootElement.classList.remove('_list-screen');
    this.rootElement.classList.add('_item-screen');
  }

  showListScreen() {
    this.rootElement.classList.remove('_item-screen');
    this.rootElement.classList.add('_list-screen');
    this.deactivateOffice();
  }

  showMapScreen() {
    this.rootElement.classList.remove('_list-screen');
    this.rootElement.classList.add('_item-screen');
    this.refreshMap();
    this.fitCenter();
  }
}