export default class Office {
  constructor(listItemElement, itemElement, bg) {
    this.settings = Object.assign({
      lat: 0,
      lng: 0,
    }, listItemElement.dataset);
    this.listItemElement = listItemElement;
    this.itemElement = itemElement;
    this.placemark = undefined;
    if (bg) {
      this.bg = bg;
    }
  }

  getLat() {
    return parseFloat(this.settings.lat);
  }

  getLng() {
    return parseFloat(this.settings.lng);
  }

  setPlacemark(placemark) {
    this.placemark = placemark;
  }

  getPlacemark() {
    return this.placemark;
  }

  show() {
    this.itemElement.classList.add('_show');
    if (this.bg) {
      this.bg.classList.add('_visible');
    }
  }

  hide() {
    this.itemElement.classList.remove('_show');
    if (this.bg) {
      this.bg.classList.remove('_visible');
    }
  }
}