const bind = () => {
  const items = document.querySelectorAll('[data-menu-children]');
  items.forEach((item) => {
    const subItems = item.querySelectorAll('li');
    subItems.forEach((subItem, key) => {
      subItem.style.transitionDelay = `${key * 120 + 100}ms`;
    });
  });
};

document.addEventListener('DOMContentLoaded', bind);