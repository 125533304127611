import { onDomReady } from '../../components/dynamic/observer';

const init = () => {
  const button = document.querySelector('[data-project-map-filter-button]');
  const input = document.querySelector('[data-project-map-filter-input]');

  if (button && input) {
    button.addEventListener('click', () => {
      input.classList.toggle('_opened');
    });
  }

  if (window.innerWidth < 768) {
    const close = document.querySelector('[data-project-map-filter-button-small]');
    if (close) {
      close.addEventListener('click', () => input.classList.toggle('_opened'));
    }
  }
};

onDomReady(() => init());