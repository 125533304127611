export default class CompareBlock {
  constructor(element) {
    this.rootElement = element;
    this.checkDifferences = element.dataset.checkDifferences !== undefined;
  }

  countElements() {
    return this.rootElement.querySelectorAll('[data-compare-block-element]').length;
  }

  getElements() {
    return this.rootElement.querySelectorAll('[data-compare-block-element]');
  }

  setVisibleElementsByIndexes(visibleIndexes) {
    this.getElements().forEach((element, index) => {
      if (visibleIndexes.indexOf(index) !== -1) {
        element.classList.remove('_hidden');
        element.classList.add('_visible');
      } else {
        element.classList.remove('_visible');
        element.classList.add('_hidden');
      }
    });
  }

  hideByDifferences() {
    if (!this.checkDifferences) {
      return;
    }

    let value = undefined;
    let hasDifferences = false;
    this.getElements().forEach((element) => {
      if (value === undefined) {
        value = element.innerHTML.trim();
        return;
      }
      if (value !== element.innerHTML.trim()) {
        hasDifferences = true;
      }
    });

    if (!hasDifferences) {
      this.hide();
    }
  }

  deleteElementByKey(key) {
    this.getElements().forEach((element) => {
      if (element.dataset.compareBlockElement === key) {
        element.remove();
      }
    });
  }

  hide() {
    this.rootElement.classList.remove('_visible');
    this.rootElement.classList.add('_hidden');
  }

  show() {
    this.rootElement.classList.remove('_hidden');
    this.rootElement.classList.add('_visible');
  }
}