import noUiSlider from 'nouislider';
import _ from 'lodash';
import wNumb from 'wnumb';
import axios from 'axios';

class Calc {
  constructor(rootElement, settings) {
    this.rootElement = rootElement;
    this.minFee = this.rootElement.dataset.minFee;
    this.defaultFee = this.rootElement.dataset.defaultFee;
    this.settings = settings;
    this.wnumb = wNumb({
      decimals: 1,
      thousand: ' ',
    });
    this.numb = wNumb({
      decimals: 0,
      thousand: ' ',
    });
    this.sumSlider = this.initSlider(this.rootElement.querySelector('[data-sum]'), {
      from: 1500000,
      to: 10000000,
      step: 10000,
      afterUpdate: () => {
        this.updateBordersForInitialFee();
      },
    });
    this.yearsSlider = this.initSlider(this.rootElement.querySelector('[data-years]'), {
      from: 1,
      to: 30,
      step: 1,
    });
    const [fromFee, toFee] = this.getBordersForInitialFee();
    this.feeSlider = this.initSlider(this.rootElement.querySelector('[data-fee]'), {
      from: fromFee,
      to: toFee,
      step: 10000,
    });
    this.sumInput = this.rootElement.querySelector('[data-sum] input[type="text"]');
    this.feeInput = this.rootElement.querySelector('[data-fee] input[type="text"]');
    this.yearsInput = this.rootElement.querySelector('[data-years] input[type="text"]');
    this.initInputs();
  }

  initInputs() {
    this.initSumInput();
    this.initFeeInput();
    this.initYearsInput();
  }

  getSumValue() {
    if (!this.sumSlider) {
      return null;
    }
    const rawSumValue = this.sumSlider.get();
    return this.wnumb.from(rawSumValue);
  }

  getRawSumValue() {
    const rawSumValue = this.rootElement.querySelector('[data-sum] [data-slider-input]').value;
    return this.wnumb.from(rawSumValue);
  }

  getFeeValue() {
    if (!this.feeSlider) {
      return null;
    }
    const rawFeeValue = this.feeSlider.get();
    return this.wnumb.from(rawFeeValue);
  }

  getYearsValue() {
    if (!this.yearsSlider) {
      return null;
    }
    const rawYearsValue = this.yearsSlider.get();
    return this.wnumb.from(rawYearsValue);
  }

  getBordersForInitialFee() {
    const intValue = this.getSumValue();
    if (!intValue) {
      return [null, null];
    }

    const initialFeePercentMin = parseFloat(this.minFee);
    const initialFeePercentMax = 90;
    return [
      initialFeePercentMin / 100 * intValue,
      initialFeePercentMax / 100 * intValue,
    ];
  }

  getPercentFee() {
    const sum = this.getSumValue();
    const fee = this.getFeeValue();
    if (!sum || !fee) {
      return null;
    }
    return fee / sum * 100;
  }

  updateBordersForInitialFee() {
    const [fromFee, toFee] = this.getBordersForInitialFee();
    if (fromFee === null) {
      return;
    }

    this.feeSlider.updateOptions({
      range: {
        min: fromFee,
        max: toFee,
      },
    });

    // Если нужно прям жестко привязываться к проценту
    const percent = this.defaultFee;
    const val = percent / 100 * this.getRawSumValue();
    this.feeSlider.set(val);
  }

  initSumInput() {
    const input = this.sumInput;
    input.addEventListener('input', _.debounce(() => {
      this.sumSlider.set([input.value, 1500000]);
    }, 1000));
  }

  initFeeInput() {
    const input = this.feeInput;
    input.addEventListener('input', _.debounce(() => {
      this.feeSlider.set([input.value, 1200000]);
    }, 1000));
  }

  initYearsInput() {
    const input = this.yearsInput;
    input.addEventListener('input', _.debounce(() => {
      this.yearsSlider.set([input.value, 3]);
    }, 1000));
  }

  initSlider(sliderElement, settings) {
    const lineElement = sliderElement.querySelector('[data-slider-line]');
    const inputElement = sliderElement.querySelector('[data-slider-input]');

    let sliderOptions = Object.assign({
      from: 0,
      to: 1,
      step: 1,
      postfix: '',
      afterUpdate: undefined,
    }, settings);
    sliderOptions = Object.assign(sliderOptions, sliderElement.dataset);

    const createOptions = {
      step: parseInt(sliderOptions.step, 10),
      range: {
        min: parseInt(sliderOptions.from, 10),
        max: parseInt(sliderOptions.to, 10),
      },
      format: wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: sliderOptions.postfix,
      }),
      start: [
        inputElement.value,
      ],
      connect: 'lower',
    };
    const slider = noUiSlider.create(lineElement, createOptions);
    const debouncedRender = _.debounce(() => {
      this.renderValues();
    }, 100);
    slider.on('update', (value, handle) => {
      inputElement.value = value;
      if (sliderOptions.afterUpdate) {
        sliderOptions.afterUpdate(value);
      }
      debouncedRender();
    });

    return slider;
  }

  isInitialized() {
    return this.feeSlider && this.sumSlider && this.yearsSlider;
  }

  async renderValues() {
    if (!this.isInitialized()) {
      return;
    }

    const sum = this.getSumValue();
    const fee = this.getFeeValue();
    const years = this.getYearsValue();

    const creditSum = sum - fee;
    let monthPay = null;
    let profitMonthPay = null;
    let baseOverPayment = null;
    let profitOverPayment = null;
    let profitSum = null;

    try {
      const response = await axios.post(this.rootElement.dataset.url, {
        flatPrice: sum,
        initialFee: fee,
        sum: creditSum,
        years,
      });
      if (response && response.data && response.data.data && response.data.data.monthPay) {
        monthPay = response.data.data.monthPay;
      }
      if (response && response.data && response.data.data && response.data.data.profitMonthPay) {
        profitMonthPay = response.data.data.profitMonthPay;
      }
      if (response && response.data && response.data.data && response.data.data.baseOverPayment) {
        baseOverPayment = response.data.data.baseOverPayment;
      }
      if (response && response.data && response.data.data && response.data.data.profitOverPayment) {
        profitOverPayment = response.data.data.profitOverPayment;
      }
      if (response && response.data && response.data.data && response.data.data.profitSum) {
        profitSum = parseInt(response.data.data.profitSum, 10);
      }
    } catch (e) {
      console.error(e);
    }

    this.rootElement.querySelector('[data-month-pay]').innerHTML = this.numb.to(monthPay);
    this.rootElement.querySelector('[data-fee-percent]').innerHTML = `${this.wnumb.to(this.getPercentFee())}%`;
    if (this.rootElement.querySelector('[data-credit-sum]')) {
      this.rootElement.querySelector('[data-credit-sum]').innerHTML = this.numb.to(creditSum);
    }
    if (this.rootElement.querySelector('[data-profit-month-pay]')) {
      this.rootElement.querySelector('[data-profit-month-pay]').innerHTML = this.numb.to(profitMonthPay);
    }
    if (this.rootElement.querySelector('[data-base-over-payment]')) {
      this.rootElement.querySelector('[data-base-over-payment]').innerHTML = this.numb.to(baseOverPayment);
    }
    if (this.rootElement.querySelector('[data-profit-over-payment]')) {
      this.rootElement.querySelector('[data-profit-over-payment]').innerHTML = this.numb.to(profitOverPayment);
    }
    if (this.rootElement.querySelector('[data-profit-sum]')) {
      this.rootElement.querySelector('[data-profit-sum]').innerHTML = this.numb.to(profitSum);
    }
  }
}

export default Calc;