import Swiper, { Navigation, Pagination } from 'swiper';

import { onDomReady } from '../../components/dynamic/observer';

Swiper.use([Navigation, Pagination]);

function initSliders() {
  document.querySelectorAll('[data-landing-slider-container]')
    .forEach((item) => {
      const slider = new Swiper(item.querySelector('[data-landing-slider]'), {
        navigation: {
          prevEl: item.querySelector('[data-landing-slider-left]'),
          nextEl: item.querySelector('[data-landing-slider-right]'),
        },
        pagination: {
          el: item.querySelector('[data-landing-slider-pagination]'),
          type: 'bullets',
          clickable: true,
        },
        slidesPerView: 'auto',
        allowTouchMove: true,
      });
    });
}

onDomReady(() => initSliders());