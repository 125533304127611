class CallTouchRequester {
  constructor() {
    this.siteId = '62117'; // Идентификатор проекта
    this.clientId = ''; // id клиента в calltouch
    this.callTouchModId = '0zdw1qfv'; // Идентификатор счетчика
    this.token = '47c694444a36dae59f3a94767d896692547af7a49e614';
    this.endpoint = 'https://api.calltouch.ru/calls-service/RestAPI/requests/';
    this.method = '/register';
    this.init();
  }

  init() {
    this.sessionIdInit();

    window.jivo_onIntroduction = () => {
      const eventType = 'Jivo - Посетитель оставил контакты в рамках активного диалога';
      const clientData = this.prepareData(eventType);
      this.sendToCallTouch(clientData.toString());
    };

    window.jivo_onOfflineFormSubmit = () => {
      const eventType = 'Jivo - Посетитель оставил заявку в оффлайн форме';
      const clientData = this.prepareData(eventType);
      this.sendToCallTouch(clientData.toString());
    };

    window.jivo_onCallStart = () => {
      const eventType = 'Jivo - Заказ обратного звонка';
      const clientData = this.prepareData(eventType);
      this.sendToCallTouch(clientData.toString());
    };
  }

  sessionIdInit() {
    setTimeout(() => {
      let val = '';
      const callTouchData = window.ct('calltracking_params', this.callTouchModId);
      if (callTouchData && callTouchData.sessionId) {
        val = callTouchData.sessionId;
      }
      if (!val) {
        val = '';
      }
      this.clientId = val;
      this.setCookie('callTouchID', val, 365);
    }, 5000);
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }

  prepareData(eventType) {
    const jivoClient = window.jivo_api.getContactInfo();
    return new URLSearchParams({
      fio: jivoClient.client_name,
      phone: jivoClient.phone,
      email: jivoClient.email,
      requestUrl: location.href,
      sessionId: this.clientId,
      subject: eventType,
    });
  }

  sendToCallTouch(clientData) {
    const url = this.endpoint + this.siteId + this.method;
    const response = this.postData(url, clientData);
  }

  async postData(url, data) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Token': this.token,
        'SiteId': this.siteId,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, HEAD, POST, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
}

export default CallTouchRequester;