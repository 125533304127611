import axios from 'axios';
import { onDomChanges } from '../../components/dynamic/observer';
import { singleBind } from '../../components/dynamic/bind';
import { notifyResponse } from '../notifications/notify';

async function favoriteToggle(linkElement) {
  let inFavorites = Boolean(linkElement.dataset.favorites);

  const currentUrl = linkElement.getAttribute('href');
  const response = await axios.post(currentUrl);

  if (notifyResponse(response) && response.data.success) {
    inFavorites = !inFavorites;
    const addUrl = linkElement.dataset.add;
    const removeUrl = linkElement.dataset.remove;

    // Переключаем вкл/выкл
    linkElement.dataset.favorites = inFavorites ? '1' : '';
    linkElement.setAttribute('href', inFavorites ? removeUrl : addUrl);

    const event = new CustomEvent('favoriteToggle', {
      detail: {
        linkElement,
        inFavorites,
      },
    });
    document.dispatchEvent(event);
  }

  if (response.data.count !== undefined) {
    // Обновляем счетчик :)
    document.querySelectorAll('[data-favorites-count]')
      .forEach((countElement) => {
        countElement.innerHTML = response.data.count;
      });
  }
}

function initFavorites() {
  document.querySelectorAll('[data-favorites]').forEach((link) => {
    singleBind(link, 'bind', 'click', (e) => {
      e.preventDefault();
      favoriteToggle(link);
    });
  });
}

onDomChanges(initFavorites);
initFavorites();