function addClass() {
  const container = document.querySelector('[data-advantage-slider]');
  if (container) {
    container.addEventListener('click', function(e) {
      const items = document.querySelectorAll('[data-advantage-item]');
      const target = e.target;

      Array.from(items).forEach(item => {
        item.classList.remove('_hovered');
      });
      target.classList.add('_hovered');
    });
  }
}
document.addEventListener('DOMContentLoaded', addClass);
document.addEventListener('DOMContentMutated', addClass);