import { onDomReady } from '../../components/dynamic/observer';
import Jax from "../../components/jax/jax";
import _ from "lodash";
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

class LayoutSlider {
  constructor(selector) {
    // this.debug('Start layout slider', selector);
    this.sliderContent = '[data-layout-slider-items]';
    this.sliderSelector = selector;
    this.leftArrow = this.sliderSelector.querySelector('[data-layouts-left]');
    this.rightArrow = this.sliderSelector.querySelector('[data-layouts-right]');
    // this.debug('Go set mode');
    this.setMode();
    // this.debug('Mode set up');

    // this.debug('Go init');
    this.init();
  }

  init() {
    // this.debug('Go init slider');
    this.initSlider();
    // this.debug('Go bind filter');
    this.bindFilter();
    // this.debug('Go bind listeners');
    this.eventListeners();
  }

  bindFilter() {
    this.form = document.querySelector('[data-layout-slider-form]');
    if (this.form) {
      this.debounce = _.debounce(() => this.request(this.form), 1000);
      const instance = this;
      this.form.querySelectorAll('input[type="checkbox"]').forEach((input) => {
        input.addEventListener('change', instance.debounce);
      });
    }
  }

  request(form) {
    const instance = this;
    const jax = new Jax(form.getAttribute('action'), form.getAttribute('method'));
    jax.send(new FormData(form)).then(html => instance.responseHandler(html));
  }

  responseHandler(html) {
    this.destroySlider();
    this.replaceContent(html);
    this.initSlider();
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;
    const newData = page.querySelector(this.sliderContent);
    const currentData = document.querySelector(this.sliderContent);
    currentData.innerHTML = newData.innerHTML;
  }

  initSlider() {
    // this.debug('Create slider', this.getOptions());
    this.setMode();
    this.slider = new Swiper(this.sliderSelector, this.getOptions());
    // console.log('Slider initialized', this.slider);
  }

  destroySlider() {
    if (this.slider) {
      // this.debug('Destroy slider', this.slider);
      this.slider.destroy();
      this.slider = undefined;
    }
  }

  getOptions() {
    const options = {
      navigation: {
        prevEl: this.leftArrow,
        nextEl: this.rightArrow,
      },
      pagination: {
        el: '[data-layout-slider-pagination]',
        clickable: true,
      },
      slidesPerView: 'auto',
      loop: false,
      rewind: false,
      slideBy: 1,
      breakpoints: {
        320: {
          spaceBetween: 22,
          allowTouchMove: true,
          pagination: {
            dynamicBullets: true,
          },
        },
        768: {
          allowTouchMove: true,
          pagination: {
            dynamicBullets: true,
          },
        },
        1259: {
          allowTouchMove: false,
        },
      },
    };

    if (this.mobileEnabled) {
      options.items = 1;
      options.autoHeight = true;
    } else {
      options.autoWidth = true;
    }

    return options;
  }

  eventListeners() {
    const instance = this;
    window.addEventListener('resize', () => instance.onResize());
  }

  onResize() {
    if (this.isMobile() !== this.mobileEnabled) {
      this.destroySlider();
      this.initSlider();
    }
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  setMode() {
    this.mobileEnabled = this.isMobile();
  }
}

onDomReady(() => {
  const sliderWrapper = document.querySelector('[data-layout-slider]');
  if (sliderWrapper) {
    new LayoutSlider(sliderWrapper);
  }
});