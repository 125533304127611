import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
    if (!document.querySelector('[data-catalog-infra-slider-container]')) {
        return;
    }
    const catalogAdvantageSlider = new MyTinySlider({
        sliderContainer: '[data-catalog-infra-slider-container]',
        sliderList: '[data-catalog-infra-slider]',
        arrowLeft: '[data-catalog-infra-left]',
        arrowRight: '[data-catalog-infra-right]',
        slideIndex: '[data-catalog-infra-index]',
        slideCount: '[data-catalog-infra-count]',
        slideBy: 1,
        speed: 700,
        nav: true,
        navPosition: 'bottom',
        controls: false,
        center: true,
        rewind: true,
        loop: false,
        responsive: {
            0: {
                gutter: 12,
                fixedWidth: 162,
            },
            768: {
                gutter: 24,
                fixedWidth: 333,
            },
            1280: {
                gutter: 29,
                fixedWidth: 360,
                items: 3.3,
            },
        },
    });
});