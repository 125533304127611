// Копирование текста из блока +
// Нажатие на элементы +
// Наведение на элементы
// Открытие всплывашки - это нажатие на кнопку/ссылку, которая и открывает всплывашку
// Взаимодействие с формой
// Успешная отправка формы
// Просмотр блока

function eventInput(e) {
  const closest = e.target.closest('[data-goal-input]');
  if (closest) {
    _proceedDataGoal(closest, 'goalInput');
  }
}

document.body.addEventListener('input', eventInput);
document.body.addEventListener('change', eventInput);

function yandexGoal(goal) {
  if (window.Ya && window.Ya._metrika) {
    window.Ya._metrika.counter.reachGoal(goal);
  }
}

function googleGoal(goal, action, category) {
  if (window.gtag) {
    window.gtag('event', action, {
      'event_category': goal,
      'category': category,
    });
  }
}

// function facebookGoal(goalFacebook) {
//   if (window.fbq) {
//     window.fbq('track', goalFacebook);
//   }
// }

function goal(goal, goalAction, category) {
  yandexGoal(goal);
  googleGoal(goal, goalAction, category);
}

// function facebookAction(goalFacebook) {
//   facebookGoal(goalFacebook);
// }

function _proceedDataGoal(element, datasetAttribute) {
  const goalValue = element.dataset[datasetAttribute];
  delete element.dataset[datasetAttribute];

  let goalCategory = element.dataset.goalCategory;
  let goalAction = element.dataset.goalAction;
  // let goalFacebook = element.dataset.goalFacebook;
  // if (!goalAction) {
  //   facebookAction(goalFacebook);
  // }
  if (datasetAttribute === 'goalClick' || !goalAction) {
    goalAction = 'click';
  }

  goal(goalValue, goalAction, goalCategory);
}

window.goal = goal;

// Events

window.onload = function() {
  const pageSucceess = document.querySelector('[data-goal-page-success]');
  if (pageSucceess) {
    const pageSucceessId = pageSucceess.dataset.id;
    let successIds = JSON.parse(localStorage.getItem('successIds')) ? JSON.parse(localStorage.getItem('successIds')) : [];
    const hasId = successIds ? successIds.indexOf(pageSucceessId) + 1 : 0;

    if (!hasId) {
      successIds.push(pageSucceessId);
      localStorage.setItem('successIds', JSON.stringify(successIds));
      _proceedDataGoal(pageSucceess, 'goalPageSuccess');
    }
  }
};

document.body.addEventListener('copy', (e) => {
  const closest = e.target.closest("[data-goal-copy]");
  if (closest) {
    _proceedDataGoal(closest, 'goalCopy');
  }
});

document.body.addEventListener('click', (e) => {
  const closest = e.target.closest('[data-goal-click]');
  if (closest) {
    _proceedDataGoal(closest, 'goalClick');
  }
});

window.addEventListener('scroll', () => {
  function getTopOfElement(element) {
    const now = new Date().getTime();
    const lastUpdate = element.dataset.documentOffsetTopTime ? element.dataset.documentOffsetTopTime : 0;
    if (now - lastUpdate > 800) {
      element.dataset.documentOffsetTop = window.pageYOffset + element.getBoundingClientRect().top;
      element.dataset.documentOffsetTopTime = now;
    }
    return parseInt(element.dataset.documentOffsetTop, 10);
  }

  document.querySelectorAll('[data-goal-see]').forEach((block) => {
    const startBorder = window.pageYOffset + window.innerHeight - window.innerHeight * 0.5;
    if (startBorder >= getTopOfElement(block)) {
      _proceedDataGoal(block, 'goalSee');
    }
  });
});

document.querySelectorAll('[data-goal-hover]').forEach(function(block) {
  block.addEventListener('mouseover', () => {
    if (block.dataset.goalHover) {
      _proceedDataGoal(block, 'goalHover');
    }
  });
});