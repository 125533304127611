export default class Office {
  constructor(element, type) {
    this.element = element;
    this.settings = Object.assign({
      lat: 0,
      lng: 0,
      zoom: 15,
      type: 'project',
    }, element.dataset);
  }

  getLat() {
    return parseFloat(this.settings.lat);
  }

  getLng() {
    return parseFloat(this.settings.lng);
  }

  getZoom() {
    return parseFloat(this.settings.zoom);
  }

  getType() {
    return this.settings.type;
  }

  setPlacemark(placemark) {
    this.placemark = placemark;
  }

  getPlacemark() {
    return this.placemark;
  }
}