// import { tns } from 'tiny-slider/src/tiny-slider';
// import bindArrows from './common/bindArrows';
// import sliderCounter from './common/sliderCounter';
import Swiper, { Navigation, Pagination } from 'swiper';

import { onDomReady } from '../../components/dynamic/observer';

Swiper.use([Navigation, Pagination]);

function initSliders() {
  document.querySelectorAll('[data-simple-slider-container]')
    .forEach((item) => {
      const slider = new Swiper(item.querySelector('[data-simple-slider]'), {
        navigation: {
          prevEl: item.querySelector('[data-simple-slider-left]'),
          nextEl: item.querySelector('[data-simple-slider-right]'),
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return ('0' + current).slice(-2) + ' / ' + ('0' + total).slice(-2);
          },
          clickable: true,
        },
        slidesPerView: 'auto',
        allowTouchMove: true,
        breakpoints: {
          320: {
            spaceBetween: 14,
            pagination: {
              type: 'bullets',
            },
          },
          768: {
            spaceBetween: 33,
          },
          1259: {
            spaceBetween: 58,
          },
        },
      });
    });

  document.querySelectorAll('[data-simple-slider-container]')
    .forEach((item, index) => {
      if (index !== 0) {
        item.classList.remove('_active');
      }
    });
}

onDomReady(() => initSliders());