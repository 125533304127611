import { onDomReady } from '../../components/dynamic/observer';

class NavMenu {
  constructor(selector) {
    this.menu = selector;
    this.list = this.menu.querySelector('[data-project-nav-menu-list]');

    if (this.menu) {
      this.items = Array.from(this.menu.querySelectorAll('[data-project-nav-menu-item]'));
    }
    this.targetsPositions = [];

    this.ids = [];
    this.moreButton = this.menu.querySelector('[data-project-nav-menu-more]');
    this.moreButtonStatus = false;
    this.dropMenuContainer = this.menu.querySelector('[data-project-nav-menu-drop-wrap]');

    if (this.dropMenuContainer) {
      this.dropMenu = this.dropMenuContainer.querySelector('[data-project-nav-menu-drop]');
    }
    this.lastUpdatedPositionsAt = undefined;

    const rootStyles = getComputedStyle(document.querySelector('.layout'));
    this.menuTranslate = rootStyles.getPropertyValue('--menu-translate').slice(0, -2);

    this.init();
    this.eventListeners();
  }

  eventListeners() {
    document.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > window.innerHeight - parseInt(this.menuTranslate, 10)) {
        this.menu.classList.add('_sticky');
      } else {
        this.menu.classList.remove('_sticky');
      }
      const top = document.documentElement.scrollTop;
      this.setActiveMenuItem(top, top + window.innerHeight);
    });
  }

  getTargetPositions() {
    this.safeUpdateTargetPositions();
    return this.targetsPositions;
  }

  safeUpdateTargetPositions() {
    const now = new Date();
    if (
      this.lastUpdatedPositionsAt === undefined
      || (now - this.lastUpdatedPositionsAt) > 1000
    ) {
      this.updateTargetPositions();
    }
  }

  updateTargetPositions() {
    this.items.forEach((item) => {
      const id = item.getAttribute('href').slice(1);
      if (id) {
        const target = document.getElementById(id);
        if (!target) {
          item.remove();
        }
        if (target) {
          const targetBounds = target.getBoundingClientRect();
          const top = targetBounds.top + document.documentElement.scrollTop;
          const bottom = top + targetBounds.height;
          this.targetsPositions[id] = {
            top,
            bottom,
          };
        }
      }
    });
  }

  init() {
    this.safeUpdateTargetPositions();

    this.items.forEach((item) => {
      const id = item.getAttribute('href').slice(1);
      this.hideItemsOverContainerWidth(item, id);
    });
  }

  hideItemsOverContainerWidth(item) {
    const listBounds = this.list.getBoundingClientRect();
    const itemBounds = item.getBoundingClientRect();

    if (itemBounds.right > listBounds.right) {
      if (!this.moreButtonStatus) {
        this.moreButtonAdder();
      }

      this.transferItemToDropMenu(item);
    }
  }

  moreButtonAdder() {
    this.moreButtonStatus = true;
    this.moreButton.classList.remove('_hidden');
  }

  transferItemToDropMenu(item) {
    if (this.dropMenu) {
      item.classList.add('_drop');
      this.dropMenu.append(item);
    }
  }

  setActiveMenuItem(top, bottom) {
    const targetPositions = this.getTargetPositions();
    let lastActiveTargetName;

    const bottomBorder = bottom - window.innerHeight / 3;
    // Получаем последний из пересекших низ экрана
    Object.keys(targetPositions).forEach((key) => {
      if (targetPositions[key].top < bottomBorder) {
        lastActiveTargetName = key;
      }
    });
    // Ставим активность нужной ссылке
    this.items.forEach((linkElement) => {
      const listElement = linkElement.parentNode;
      if (!listElement) {
        return;
      }
      if (lastActiveTargetName === linkElement.getAttribute('href').slice(1)) {
        listElement.classList.add('_active');
      } else {
        listElement.classList.remove('_active');
      }
    });
  }
}


const init = () => {
  const menu = document.querySelector('[data-project-nav-menu]');
  if (menu) {
    const navmenu = new NavMenu(menu);
  }
};

onDomReady(() => init());