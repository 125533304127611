import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-catalog-advantages-slider-container]')) {
    return;
  }
  const catalogAdvantageSlider = new MyTinySlider({
    sliderContainer: '[data-catalog-advantages-slider-container]',
    sliderList: '[data-catalog-advantages-slider]',
    arrowLeft: '[data-catalog-advantages-left]',
    arrowRight: '[data-catalog-advantages-right]',
    slideIndex: '[data-catalog-advantages-slider-index]',
    slideCount: '[data-catalog-advantages-count]',
    slideBy: 1,
    nav: true,
    navPosition: 'bottom',
    speed: 700,
    center: true,
    controls: false,
    rewind: true,
    loop: false,
    fillingLine: true,
    responsive: {
      0: {
        gutter: 12,
        fixedWidth: 338,
      },
      768: {
        gutter: 24,
        fixedWidth: 404,
      },
      1280: {
        gutter: 28,
        fixedWidth: 574,
      },
    },
  });
});