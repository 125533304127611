import axios from 'axios';
import { onDomChanges } from '../../components/dynamic/observer';
import { singleBind } from '../../components/dynamic/bind';
import { notifyResponse } from '../notifications/notify';

async function compareToggle(linkElement) {
  let inCompare = Boolean(linkElement.dataset.compare);

  const currentUrl = linkElement.getAttribute('href');
  const response = await axios.post(currentUrl);

  if (notifyResponse(response) && response.data.success) {
    inCompare = !inCompare;
    const addUrl = linkElement.dataset.add;
    const removeUrl = linkElement.dataset.remove;

    // Переключаем вкл/выкл
    linkElement.dataset.compare = inCompare ? '1' : '';
    linkElement.setAttribute('href', inCompare ? removeUrl : addUrl);

    const event = new CustomEvent('compareToggle', {
      detail: {
        linkElement,
        inCompare,
      },
    });
    document.dispatchEvent(event);
  }

  if (response.data.count !== undefined) {
    // Обновляем счетчик :)
    document.querySelectorAll('[data-compare-count]')
      .forEach((countElement) => {
        countElement.innerHTML = response.data.count;
      });
  }
}

function initCompare() {
  document.querySelectorAll('[data-compare]').forEach((link) => {
    singleBind(link, 'bind', 'click', (e) => {
      e.preventDefault();
      compareToggle(link);
    });
  });
}

onDomChanges(initCompare);
initCompare();