import Jax from '../../components/jax/jax';
import { onDomChanges, onDomReady } from '../../components/dynamic/observer';

class AboutBuildingFilter {
  constructor(form) {
    this.form = form;
    this.checkedInputs = Array.from(form.querySelectorAll('input[type="checkbox"], input[type="radio"]'));

    this.init();
  }

  init() {
    this.bind();
  }

  bind() {
    this.bindChecked();
  }

  bindChecked() {
    const instance = this;
    this.checkedInputs.forEach((input) => {
      input.addEventListener('change', () => instance.request(instance.form));
    });
  }

  request(form) {
    const instance = this;
    const jax = new Jax(
      form.getAttribute('action'),
      form.getAttribute('method'),
    );
    const formData = new FormData(form);
    formData.append('activeTab', 'buildings');
    jax.send(formData).then(html => instance.responseHandler(html));
  }

  responseHandler(html) {
    const instance = this;
    const page = document.createElement('div');
    page.innerHTML = html;

    instance.replaceContent(page);
    document.dispatchEvent(new Event('DOMContentMutated'));
  }

  replaceContent(page) {
    const newData = page.querySelector('[data-about-building-content]');
    const currentData = document.querySelector('[data-about-building-content]');
    currentData.innerHTML = newData.innerHTML;
  }
}

const init = () => {
  const form = document.querySelector('[data-about-building-filter]:not([data-initialized])');
  if (form) {
    form.dataset.initialized = 'true';
    const aboutBuildingFilter = new AboutBuildingFilter(form);
  }
};

onDomReady(() => init());
onDomChanges(() => init());