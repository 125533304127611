function bindNavCounter(swiper, currentEl, totalEl) {
  const changeValues = () => {
    const countSlides = swiper.slides.filter(slide => !slide.classList.contains('swiper-slide-duplicate')).length;
    const currentSlide = swiper.realIndex + 1;
    currentEl.innerHTML = currentSlide;
    totalEl.innerHTML = countSlides;
  };

  changeValues();

  swiper.on('slideChange', changeValues);
  swiper.on('afterInit', changeValues);
}

export {
  bindNavCounter,
};