const sliderCounter = (sliderContainer, slideIndex, slideCount, slider) => {
  if (slider && slideIndex && slideIndex) {
    const slidesIndexText = sliderContainer.querySelector(slideIndex);
    const slidesCountText = sliderContainer.querySelector(slideCount);
    let info = slider.getInfo();
    let slidesIndex = info.displayIndex;
    let slidesCount = info.slideCount;

    if (slidesIndexText && slidesCountText) {
      slidesIndexText.innerHTML = slidesIndex < 10 ? `0${slidesIndex}` : slidesIndex;
      slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;

      slider.events.on('transitionStart', () => {
        info = slider.getInfo();
        slidesIndex = info.displayIndex;
        slidesCount = info.slideCount;
        slidesIndexText.innerHTML = slidesIndex < 10 ? `0${slidesIndex}` : slidesIndex;
        slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;
      });
    }
  }
};

export default sliderCounter;