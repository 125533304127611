import Jax from '../../components/jax/jax';
import { onDomReady } from '../../components/dynamic/observer';
import _ from 'lodash';

class SellerFloorFilter {
  constructor(form) {
    this.form = form;
    this.top = form.querySelector('[data-filter-top]');
    this.data = document.querySelector('[data-catalog-content]');
    this.addressSelect = (this.form.querySelector('[data-field-address] [data-custom-select]'));
    this.sectionSelect = (this.form.querySelector('[data-field-sections] [data-custom-select]'));
    this.floorSelect = (this.form.querySelector('[data-field-floors] [data-custom-select]'));
    this.init();
  }

  init() {
    this.initInputs();
    this.bindSubmit();
    this.sectionSelect.dispatchEvent(new CustomEvent('disable'));
    this.floorSelect.dispatchEvent(new CustomEvent('disable'));
  }

  initInputs() {
    this.checkedInputs = Array.from(this.form.querySelectorAll('[data-field-floors] input[type="radio"]'));
    this.bindChains();
    this.bindChecked();
  }

  bindChains() {
    const addressInputs = Array.from(this.form.querySelectorAll('[data-field-address] input[type="radio"]'));
    const sectionInputs = Array.from(this.form.querySelectorAll('[data-field-sections] input[type="radio"]'));
    const floorInputs = Array.from(this.form.querySelectorAll('[data-field-floors] input[type="radio"]'));
    if (addressInputs.length && sectionInputs.length) {
      this.initSectionInputs(addressInputs, sectionInputs, floorInputs);
      this.initFloorInputs(sectionInputs, floorInputs);
    }
  }

  initSectionInputs(addressInputs, sectionInputs, floorInputs) {
    addressInputs.forEach((addressInput) => {
      addressInput.addEventListener('change', (e) => {
        const addressNames = [];
        const zeroSectionInput = sectionInputs.find(sectionInput => sectionInput.value === '0');
        const zeroFloorInput = floorInputs.find(floorInput => floorInput.value === '0');
        zeroSectionInput.checked = true;
        zeroFloorInput.checked = true;
        if (zeroSectionInput.checked === true) {
          this.sectionSelect.dispatchEvent(new CustomEvent('disable'));
        }
        zeroSectionInput.dispatchEvent(new Event('change'));
        zeroFloorInput.dispatchEvent(new Event('change'));

        addressInputs
          .filter(checkedInput => checkedInput.checked && checkedInput.value !== '0')
          .forEach((checkedInput) => {
            const inputId = checkedInput.id;
            const inputLabelText = document.querySelector("label[for='" + inputId + "']").innerText;
            addressNames.push(inputLabelText);
          });
        sectionInputs.forEach((sectionInput) => {
          if (addressInput.checked && addressInput.value !== '0') {
            this.sectionSelect.dispatchEvent(new CustomEvent('enable'));
          }
          const sectionBuildingAddress = sectionInput.dataset.buildingAddress;
          if (addressNames.length === 0 || !sectionBuildingAddress || addressNames.includes(sectionBuildingAddress)) {
            sectionInput.parentNode.classList.remove('_hidden');
          } else {
            sectionInput.parentNode.classList.add('_hidden');
          }
        });
      });
    });
  }

  initFloorInputs(sectionInputs, floorInputs) {
    sectionInputs.forEach((sectionInput) => {
      sectionInput.addEventListener('change', (e) => {
        const sectionIds = [];
        const zeroInput = floorInputs.find(floorInput => floorInput.value === '0');
        if (zeroInput.checked === true) {
          this.floorSelect.dispatchEvent(new CustomEvent('disable'));
        }
        zeroInput.dispatchEvent(new Event('change'));

        sectionInputs
          .filter(checkedInput => checkedInput.checked && checkedInput.value !== '0')
          .forEach(checkedInput => sectionIds.push(parseInt(checkedInput.value, 10)));
        floorInputs.forEach((floorInput) => {
          if (sectionInput.checked && sectionInput.value !== '0') {
            this.floorSelect.dispatchEvent(new CustomEvent('enable'));
          } else {
            this.floorSelect.dispatchEvent(new CustomEvent('disable'));
          }
          const floorSectionId = parseInt(floorInput.dataset.sectionId, 10);
          if (sectionIds.length === 0 || !floorSectionId || sectionIds.includes(floorSectionId)) {
            floorInput.parentNode.classList.remove('_hidden');
          } else {
            floorInput.parentNode.classList.add('_hidden');
          }
        });
      });
    });
  }

  bindSubmit() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.request(instance.form);
    });
  }

  replaceCatalogContent(page) {
    const newData = page.querySelector('[data-catalog-content]');
    const currentData = document.querySelector('[data-catalog-content]');
    if (newData && currentData) {
      currentData.innerHTML = newData.innerHTML;
    }
  }

  request(form) {
    const instance = this;
    const jax = new Jax(form.getAttribute('action'), form.getAttribute('method'));
    jax.send(new FormData(form)).then(html => instance.responseHandler(html));
  }

  responseHandler(html) {
    const instance = this;
    const page = document.createElement('div');
    page.innerHTML = html;
    instance.replaceCatalogContent(page);
    document.dispatchEvent(new Event('DOMContentMutated'));
    document.dispatchEvent(new Event('CatalogReplaced'));
  }

  bindChecked() {
    // const instance = this;
    this.checkedInputs.forEach((input) => {
      if (input.value !== 0 && input.value !== '0') {
        input.addEventListener('change', () => {
          this.data.classList.remove('_hidden');
          this.request(this.form);
          document.dispatchEvent(new CustomEvent('request-floor-genplan-scene'));
        });
      }
    });
  }
}

onDomReady(() => {
  const form = document.querySelector('[data-floor-filter]');
  if (form) {
    new SellerFloorFilter(form);
  }
});