import axios from 'axios';
import { onDomChanges } from '../../components/dynamic/observer';
import { singleBind } from '../../components/dynamic/bind';
import { notifyResponse } from '../notifications/notify';

onDomChanges(() => {
  document.querySelectorAll('[data-agent-admin-checkbox]').forEach((adminCheckbox) => {
    singleBind(adminCheckbox, 'adminCheckbox', 'change', async () => {
      const response = await axios.post(adminCheckbox.dataset.url, new URLSearchParams({
        id: adminCheckbox.dataset.id,
        admin: adminCheckbox.checked,
      }));
      notifyResponse(response);
    });
  });
});