import {onDomReady} from "../../components/dynamic/observer";

// TODO чуть чуть оптимизировать
class HoverListener {

    items = undefined;

    constructor(selector) {
        if (selector) {
            this.items = selector.querySelectorAll('[data-hovered-item]');
            this.init();
        }
    }

    init() {
        this.catalogItemsMouseClickListener();
        this.catalogItemsMouseEnterListener();
        this.catalogItemsMouseLeaveListener();
    }

    catalogItemsMouseEnterListener() {
        const instance = this;
        instance.items.forEach((item) => {
            item.addEventListener('mouseenter', () => {
                this.removeHovered();
                this.addShadow();
                item.classList.remove('_shadow');
                item.classList.add('_hovered');
            });
        });
    }

    catalogItemsMouseLeaveListener() {
        const instance = this;
        instance.items.forEach((item) => {
            item.addEventListener('mouseleave', () => {
                this.removeHovered();
                this.removeShadow();
            });
        });
    }

    catalogItemsMouseClickListener() {
        const instance = this;
        instance.items.forEach((item) => {
            item.addEventListener('click', () => {
                if (!item.classList.contains('_opened') && item.classList.contains('_hovered')) {
                    this.removeHovered();
                    this.removeShadow();
                }
            });
        });
    }

    removeHovered() {
        this.items.forEach((item) => {
            if (item.classList.contains('_hovered')) {
                item.classList.remove('_hovered');
            }
        });
    }

    addShadow() {
        this.items.forEach((item) => {
            item.classList.add('_shadow');
        });
    }

    removeShadow() {
        this.items.forEach((item) => {
            if (item.classList.contains('_shadow')) {
                item.classList.remove('_shadow');
            }
        });
    }
}

function init() {
    document.querySelectorAll('[data-hovered-container]').forEach((item) => {
        const hoverListener = new HoverListener(item);
    });
}

onDomReady(() => {
    init()
});