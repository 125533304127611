import { onDomReady } from '../../components/dynamic/observer';
import { tns } from 'tiny-slider/src/tiny-slider';
import bindArrows from './common/bindArrows';

onDomReady(() => {
  const showRoomsContainers = document.querySelectorAll('[data-show-room-container]');
  if (!showRoomsContainers.length) {
    return;
  }

  showRoomsContainers.forEach((container) => {
    const arrow = container.querySelector('[data-circle]');
    const sliderElement = container.querySelector('[data-show-room-slider]');
    const options = {
      container: sliderElement,
      slideBy: 1,
      items: 1,
      nav: false,
      center: false,
      autoplay: true,
      speed: 700,
      autoplayButtonOutput: false,
      animateIn: 'animate_in',
      animateOut: 'animate_out',
      animateNormal: 'normal',
      mode: 'gallery',
      controls: false,
      rewind: false,
      loop: true,
      onInit: () => {
        if (arrow && !arrow.classList.contains('_active')) {
          arrow.classList.add('_active');
        }
      },
    };
    const showRoomSlider = tns(options);
    bindArrows('hui', '[data-show-right]', showRoomSlider, container);
    showRoomSlider.events.on('transitionStart', () => {
      arrow.classList.remove('_active');
      setTimeout(() => {
        if (!arrow.classList.contains('_active')) {
          arrow.classList.add('_active');
        }
      }, 500);
    });
  });
});