import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-advantage-slider-container]')) {
    return;
  }
  const advantageSlider = new MyTinySlider({
    sliderContainer: '[data-advantage-slider-container]',
    sliderList: '[data-advantage-slider]',
    arrowLeft: '[data-advantage-left]',
    arrowRight: '[data-advantage-right]',
    slideIndex: '[data-advantage-slider-index]',
    slideCount: '[data-advantage-count]',
    slideBy: 1,
    nav: true,
    navPosition: 'bottom',
    speed: 700,
    center: true,
    controls: false,
    rewind: true,
    loop: false,
    fillingLine: true,
    responsive: {
      0: {
        items: 1.1,
        gutter: 12,
        fixedWidth: 304,
      },
      768: {
        items: 2.1,
        gutter: 24,
        fixedWidth: 333,
      },
      1280: {
        items: 4,
        gutter: 28,
        fixedWidth: 380,
      },
    },
  });
});