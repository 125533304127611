import { onDomReady } from '../../components/dynamic/observer';
import { tns } from 'tiny-slider/src/tiny-slider';
import updateFilling from './common/updateFilling';


class ProgressSlider {
  constructor(container) {
    this.container = container;
    this.sliderSelector = '[data-project-construction-progress-slider]';
    this.leftArrow = container.querySelector('[data-project-construction-progress-left]');
    this.rightArrow = container.querySelector('[data-project-construction-progress-right]');
    this.slidesIndexText = container.querySelector('[data-project-construction-progress-slider-index]');
    this.slidesCountText = container.querySelector('[data-project-construction-progress-count]');
    this.hasSlider = this.container.dataset.projectConstructionProgressSliderContainer === 'false';
    this.year = 0;
    // this.month = 0;
    this.building = 0;
    this.yearBuilding = {};
    this.blockChangeEvent = false;

    this.init();
  }

  init() {
    this.initAlbums();
    if (this.hasSlider) {
      this.initSlider();
      this.bindArrows();
    }
    this.initInputs();
    // this.initYearMonthList();

    this.bindYears();
    this.bindBuildings();
    // this.bindMonth();
  }

  initYearBuildingList() {
    this.yearInputs.forEach((yearInput) => {
      const year = parseInt(yearInput.value, 10);
      if (year) {
        this.yearBuilding[year] = [];
        this.albums.forEach((album) => {
          if (year === album.year) {
            album.element.querySelectorAll('[data-progress-item]').forEach((item) => {
              const buildingId = parseInt(item.dataset.buildingId, 10);
              if (!this.yearBuilding[year].includes(buildingId)) {
                this.yearBuilding[year].push(buildingId);
              }
            });
          }
        });
      }
    });
  }

  bindYears() {
    const instance = this;
    this.yearInputs.forEach((yearInput) => {
      yearInput.addEventListener('change', (e) => {
        instance.year = parseInt(yearInput.value, 10);
        instance.updateBuildings();
        instance.rebuild();
      });
    });
  }

  updateYears() {
    const instance = this;
    this.yearInputs.forEach((yearInput) => {
      const value = parseInt(yearInput.value, 10);
      if (value) {
        if (instance.building && !instance.yearBuilding[value].includes(instance.building)) {
          yearInput.parentNode.classList.add('_hidden');
        } else {
          yearInput.parentNode.classList.remove('_hidden');
        }
      }
    });
  }

  bindBuildings() {
    if (this.buildingInputs) {
      const instance = this;
      this.buildingInputs.forEach((buildInput) => {
        buildInput.addEventListener('change', (e) => {
          instance.building = parseInt(buildInput.value, 10);
          instance.updateYears();
          instance.rebuild();
        });
      });
    }
  }

  updateBuildings() {
    const instance = this;
    this.buildingInputs.forEach((buildingInput) => {
      const value = parseInt(buildingInput.value, 10);
      if (value) {
        if (this.year && !instance.yearBuilding[instance.year].includes(value)) {
          buildingInput.parentNode.classList.add('_hidden');
        } else {
          buildingInput.parentNode.classList.remove('_hidden');
        }
      }
    });
  }

  rebuild() {
    if (this.hasSlider) {
      this.destroy();
      this.clear();
      this.append();
      this.initSlider();
    } else {
      this.clear();
      this.append();
    }

    window.refreshFsLightbox();
  }

  destroy() {
    this.slider.destroy();
  }

  clear() {
    this.container.querySelectorAll('[data-album]').forEach(album => album.remove());
  }

  append() {
    this.albums.forEach((album) => {
      if (this.year && this.year !== album.year) {
        return;
      }

      const clonedAlbum = album.element.cloneNode(true);
      if (this.buildingSelect && !this.filterByBuilding(clonedAlbum)) {
        return;
      }

      const count = clonedAlbum.querySelectorAll('[data-progress-item]').length;
      clonedAlbum.querySelector('[data-images-count]').innerHTML = count;
      this.container.querySelector(this.sliderSelector).append(clonedAlbum);
    });
  }

  filterByBuilding(album) {
    if (this.building) {
      Array.from(album.querySelectorAll('[data-progress-item]'))
        .filter(item => parseInt(item.dataset.buildingId, 10) !== this.building)
        .forEach(item => item.remove());

    }

    return album.querySelectorAll('[data-progress-item]').length;
  }

  initInputs() {
    this.yearSelect = this.container.querySelector('[data-field-year] [data-custom-select]');
    this.monthSelect = this.container.querySelector('[data-field-month] [data-custom-select]');
    this.buildingSelect = this.container.querySelector('[data-field-building] [data-custom-select]');


    this.yearInputs = Array.from(this.yearSelect.querySelectorAll('input[type="radio"]'));
    if (this.buildingSelect) {
      this.buildingInputs = Array.from(this.buildingSelect.querySelectorAll('input[type="radio"]'));
      this.initYearBuildingList();
    }
  }

  initAlbums() {
    this.albums = [];
    this.container.querySelectorAll('[data-album]').forEach((album) => {
      this.albums.push({
        element: album.cloneNode(true),
        year: parseInt(album.dataset.year, 10),
        month: parseInt(album.dataset.month, 10),
      });
    });
  }

  disableMonth() {
    this.month = 0;
    this.monthSelect.dispatchEvent(new CustomEvent('disable'));
  }

  enableMonth() {
    this.monthSelect.dispatchEvent(new CustomEvent('enable'));
  }

  bindArrows() {
    const instance = this;
    this.leftArrow.addEventListener('click', () => {
      if (instance.slider) {
        instance.slider.goTo('prev');
      }
    });
    this.rightArrow.addEventListener('click', () => {
      if (instance.slider) {
        instance.slider.goTo('next');
      }
    });
  }

  initSlider() {
    this.slider = tns({
      container: this.container.querySelector(this.sliderSelector),
      slideBy: 1,
      nav: true,
      navPosition: 'bottom',
      speed: 700,
      center: true,
      controls: false,
      rewind: true,
      loop: false,
      autoPlay: false,
      arrows: false,
      responsive: {
        0: {
          items: 1.1,
          gutter: 12,
          fixedWidth: 304,
        },
        768: {
          items: 2.1,
          gutter: 24,
          fixedWidth: 333,
        },
        1280: {
          items: 4,
          gutter: 28,
          fixedWidth: 380,
        },
      },
    });


    this.slider.events.on('indexChanged', () => {
      updateFilling(this.slider.getInfo());
    });
    updateFilling(this.slider.getInfo());

    this.slider.events.on('transitionStart', () => {
      this.updateCounters();
    });
    this.updateCounters();
  }

  updateCounters() {
    const info = this.slider.getInfo();
    const slidesIndex = info.displayIndex;
    const slidesCount = info.slideCount;
    this.slidesIndexText.innerHTML = slidesIndex < 10 ? `0${slidesIndex}` : slidesIndex;
    this.slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;
  }
}

onDomReady(() => {
  const progressContainer = document.querySelector('[data-project-construction-progress-slider-container]');
  if (progressContainer) {
    new ProgressSlider(progressContainer);
  }
});