import tippy from 'tippy.js';
import { onDomChanges } from '../../components/dynamic/observer';

function initTooltips() {
  const isTouch = (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));

  if (!isTouch) {
    document.querySelectorAll('[data-tooltip]').forEach((item) => {
      tippy(item, {
        content: item.dataset.tooltip,
      });
    });
  }
}

onDomChanges(initTooltips);
initTooltips();