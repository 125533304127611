import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-switchable-slider-container]')) {
    return;
  }
  const switchableSlider = new MyTinySlider({
    sliderContainer: '[data-switchable-slider-container]',
    sliderList: '[data-switchable-slider]',
    arrowLeft: '[data-switchable-left]',
    arrowRight: '[data-switchable-right]',
    slideIndex: '[data-switchable-slider-index]',
    slideCount: '[data-switchable-count]',
    slideBy: 1,
    items: 1,
    nav: true,
    navPosition: 'bottom',
    speed: 1300,
    mode: 'gallery',
    animateIn: 'animate_in',
    animateOut: 'animate_out',
    animateNormal: 'normal',
    center: false,
    controls: false,
    rewind: true,
    loop: false,
    preventActionWhenRunning: true,
  });
});