import { tns } from 'tiny-slider/src/tiny-slider';
import bindArrows from './common/bindArrows';
import sliderCounter from './common/sliderCounter';
import { onDomReady } from '../../components/dynamic/observer';

function initSliders() {
  document.querySelectorAll('[data-project-renovations-slider-complicated-container]')
    .forEach((item) => {
      const slider = tns({
        container: item.querySelector('[data-project-renovations-slider-complicated]'),
        slideBy: 1,
        items: 1,
        nav: false,
        speed: 1000,
        controls: false,
        rewind: true,
        loop: false,
        autoplayButtonOutput: false,
        preventActionWhenRunning: true,
        mode: 'gallery',
        animateIn: 'animate_in',
        animateOut: 'animate_out',
        animateNormal: 'normal',
      });

      bindArrows(
        '[data-project-renovations-slider-complicated-left]',
        '[data-project-renovations-slider-complicated-right]',
        slider,
        item,
      );

      sliderCounter(
        item,
        '[data-project-renovations-slider-complicated-index]',
        '[data-project-renovations-slider-complicated-count]',
        slider,
      );
    });
}

onDomReady(() => initSliders());