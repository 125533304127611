import { onDomReady } from '../../components/dynamic/observer';

class AwardsFilter {
  constructor(container) {
    this.container = container;
    this.inputs = container.querySelectorAll('[data-awards-select] input[type="radio"]');
    this.itemsContainer = container.querySelector('[data-awards-item-container]');
    this.items = Array.from(container.querySelectorAll('[data-awards-item]'));

    this.eventListeners();
  }

  eventListeners() {
    this.inputs.forEach((input) => {
      input.addEventListener('change', () => {
        this.itemsContainer.innerHTML = '';

        this.items.forEach((item) => {
          if (input.value === '0') {
            this.itemsContainer.append(item);
          } else if (item.dataset.awardsYear === input.value) {
            this.itemsContainer.append(item);
          }
        });
      });
    });
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-awards-container]')
    .forEach((item) => {
      new AwardsFilter(item);
    });
});