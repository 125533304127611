import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-article-page-container]')) {
    return;
  }
  const articleSlider = new MyTinySlider({
    sliderContainer: '[data-article-page-container]',
    sliderList: '[data-article-page-slider]',
    arrowLeft: '[data-article-page-left]',
    arrowRight: '[data-article-page-right]',
    slideIndex: '[data-article-page-slider-index]',
    slideCount: '[data-article-page-count]',
    slideBy: 1,
    items: 1,
    mode: 'gallery',
    animateIn: 'fade-in',
    animateOut: 'fade-out',
    animateNormal: 'normal',
    nav: true,
    navPosition: 'bottom',
    speed: 700,
    center: true,
    controls: true,
    rewind: true,
    loop: true,
  });
});