import LiveEvent from '../../components/live/live';
import {scrollToTarget} from '../../components/scroll/scroll';

new LiveEvent('click', '[data-scroll-link], .scroll-link', function scrollLink(e) {
    e.preventDefault();
    let target = null;
    if (this.dataset.selector) {
        target = document.querySelector(this.dataset.selector);
    } else {
        target = document.querySelector(this.getAttribute('href'));
    }
    const offset = parseInt(this.dataset.offset) || 0;
    if (target) {
        scrollToTarget(target, offset);
    }
});

// adds class for header when the menu is opened
new LiveEvent('click', '[data-header-button]', function toggleLink(e) {
    e.preventDefault();
    const target = document.querySelector(this.dataset.header);
    target.classList.toggle(this.dataset.headertoggle);
});

new LiveEvent('click', '[data-toggle-link], .toggle-link', function toggleLink(e) {
    e.preventDefault();
    const target = document.querySelector(this.dataset.selector);
    target.classList.toggle(this.dataset.toggle);
});

new LiveEvent('click', '[data-toggle-all-link]', function toggleLink(e) {
    e.preventDefault();
    const targets = document.querySelectorAll(this.dataset.selector);
    targets.forEach((target) => {
        target.classList.toggle(this.dataset.toggle);
    });
});

new LiveEvent('click', '[data-toggle-all-link]', function toggleLink(e) {
    e.preventDefault();
    const targets = document.querySelectorAll(this.dataset.selector);
    targets.forEach((target) => {
        target.classList.toggle(this.dataset.toggle);
    });
});

document.addEventListener('click', (e) => {
    document.querySelectorAll('[data-miss-click]').forEach((missClickElement) => {
        if (!missClickElement.contains(e.target)) {
            missClickElement.classList.remove(missClickElement.dataset.missRemoveClass);
        }
    });
});


document.querySelectorAll('[data-toggle-pwd]').forEach((item) => {
    item.addEventListener('click', (e) => {
        e.preventDefault();

        const parent = item.closest('[data-password-toggle-block]');
        const input = parent.querySelector('input');

        if (input.type === 'password') {
            input.type = 'text';
        } else {
            input.type = 'password';
        }
        input.focus();
    });
});
