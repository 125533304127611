import { Genplan } from './genplan';

function init() {
  setTimeout(() => {
    document.querySelectorAll('[data-gen]:not([data-initialized="true"])').forEach((htmlElement) => {
      new Genplan(htmlElement, {
        // Отступ точки от края видимой области
        poiGap: 70,
        // Отступ балуна от края видимой области
        balloonGap: 10,
        // Проверка на то, может ли пользователь скроллить включается ниже данного значения ширины
        // экрана
        scrollableToWidth: 1200,
        // Высота мини-карты
        minimapHeight: 50,
        // contain / cover - аналогию, я думаю, вы поняли
        size: htmlElement.dataset.contain ? 'contain' : 'cover',
        // Важные точки интереса (объекты) для скролл-режима
        // они определяют какую часть генплана показывать сначала в мобильном режиме
        // проще говоря на сколько скроллить изначально в мобильном (скроллящемся) режиме
        scrollableImportantObjectTypes: ['important'],
        center: htmlElement.dataset.center ? htmlElement.dataset.center : null,
      });
      htmlElement.dataset.initialized = 'true';
    });
    if (window.location.hash) {
      document.dispatchEvent(new CustomEvent('request-openen-genplan-scene', {
        detail: {
          sceneName: window.location.hash.substring(1),
        },
      }));
    }
  }, 2000);
}

// Инициализируем генлплана во время дебаунса фильтра по этажам
document.addEventListener('request-floor-genplan-scene', (e) => {
  init();
});

// Инициализируемся на DOMContentMutated
document.addEventListener('DOMContentMutated', (e) => {
  init();
});

// Инициализируемся на DOMContentLoaded
document.addEventListener('DOMContentLoaded', () => {
  init();
});