import Jax from '../../../components/jax/jax';
import { notify } from '../../notifications/notify';

class ManageRequest {
  constructor(form) {
    this.form = form;
    this.init();
  }

  init() {
    this.bind();
  }

  bind() {
    this.bindForm();
  }

  bindForm() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.request(instance.form);
    });
  }

  request(form) {
    const filterForm = document.querySelector('[data-layout-filter]');
    const instance = this;
    const jaxForm = new Jax(
      form.getAttribute('action'),
      form.getAttribute('method'),
    );
    const jaxFilter = new Jax(
      filterForm.getAttribute('action'),
      filterForm.getAttribute('method'),
    );
    jaxForm.send(new FormData(form)).then((data) => {
      const response = JSON.parse(data);
      if (response.templateName === 'success') {
        jaxFilter.send(new FormData(filterForm)).then(html => instance.responseHandler(html, response));
      } else {
        notify({
          templateName: 'error',
          timeout: 5000,
          data: {
            message: `${response.data.message}`,
          },
        });
      }
    });
  }

  responseHandler(html, response) {
    const instance = this;
    const page = document.createElement('div');
    page.innerHTML = html;
    instance.replaceContent(page);
    notify({
      templateName: 'success',
      timeout: 5000,
      data: {
        message: `${response.data.message}`,
      },
    });
    document.dispatchEvent(new Event('DOMContentMutated'));
    document.dispatchEvent(new Event('BlogReplaced'));
  }

  replaceContent(page) {
    const formId = this.form.getAttribute('id');
    const newForm = page.querySelector(`#${formId}`);
    const newData = newForm.querySelector('[data-form-content]');
    const currentData = this.form.querySelector('[data-form-content]');
    currentData.innerHTML = newData.innerHTML;
  }
}

function getForms() {
  setTimeout(() => {
    const forms = document.querySelectorAll('[data-manage-form]:not([data-initialized="true"])');
    forms.forEach((form) => {
      if (form && !form.getAttribute('initialized')) {
        new ManageRequest(form);
        form.dataset.initialized = 'true';
      }
    });
  }, 2000);
}

document.addEventListener('DOMContentLoaded', () => {
  getForms();
});

document.addEventListener('CatalogReplaced', () => {
  getForms();
});