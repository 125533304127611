// Кликаем на ссылку яндекс-шаринга
document.querySelectorAll('[data-share-opener]').forEach((proxy) => {
  proxy.addEventListener('click', (e) => {
    // Если есть открытые списки шаринга - ничего не делаем
    if (proxy.parentElement.querySelectorAll('.ya-share2__popup_visible').length === 0) {
      // Вызываем клик
      // Яндекс зачем-то следит за контекстом исполнения
      // события, поэтому теряем контекст через setTimeout
      setTimeout(() => {
        proxy.parentElement.querySelector('.ya-share2__link_more')
          .click();
      }, 10);
    }
  });
});