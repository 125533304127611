import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";


function setSizeModalIframe() {
  const modalElement = document.querySelector('.modal__container.tour');
  if (!modalElement) {
    return;
  }

  const style = window.getComputedStyle(modalElement);
  const padding = style.getPropertyValue('--modal-content-padding');
  const paddingInt = parseInt(padding);


  const iframe = modalElement.querySelector('iframe');
  const fullHeight = window.innerHeight;
  const fullWidth = window.innerWidth;

  const iframeWidth = fullWidth - (paddingInt * 2) - 70;
  const iframeHeight = fullHeight - (paddingInt * 2) - 70;

  iframe.setAttribute('width', iframeWidth);
  iframe.setAttribute('height', iframeHeight);
}


new LiveEvent('click', '[data-tour-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  const theme = link.dataset.theme ? link.dataset.theme : 'tour';
  let modal = new Modal(this, {
    closerText: '',
    asIframe: true,
    theme,
    onAfterOpen: function () {
      setSizeModalIframe();
    },
  });
  return false;
});

window.addEventListener('resize', () => {
  setSizeModalIframe();
});