import LiveEvent from '../../components/live/live';
import Modal from '../../components/modal/modal';

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const setTheme = link.dataset.theme ? link.dataset.theme : 'default';
  const successTimeoutInSecconds = link.dataset.successTimeout ? link.dataset.successTimeout : 3;
  const successTimeoutInMs = successTimeoutInSecconds * 1000;
  const modal = new Modal(this, {
    theme: setTheme,
    closerText: '',
    closeOnSuccessDelay: successTimeoutInMs,
    onFormSuccess() {
      const submitEvent = new Event('formSubmit');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);
    },
  });
  return false;
});