import Swiper, {Navigation, EffectFade, Controller} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';
import { bindNavCounter } from '../common/swiper';

Swiper.use([Navigation, EffectFade, Controller]);

onDomReady(() => {
  const whiteboxEl = document.querySelector('[data-whitebox-slider]');
  let whiteboxSlider = null;
  if (whiteboxEl) {
    whiteboxSlider = new Swiper(whiteboxEl, {
      navigation: {
        prevEl: document.querySelector('[data-whitebox-slider-arrow-prev]'),
        nextEl: document.querySelector('[data-whitebox-slider-arrow-next]'),
      },
      slidesPerView: 1,
      loop: true,
      rewind: false,
      speed: 1300,
    });

    bindNavCounter(
      whiteboxSlider,
      document.querySelector('[data-whitebox-slider-nav-current]'),
      document.querySelector('[data-whitebox-slider-nav-total]'),
    );

    // Меняем слайд на заднике
    whiteboxSlider.on('slideChange', () => {
      document.dispatchEvent(new CustomEvent('setBackgroundSliderItem', {
        detail: {
          number: whiteboxSlider.activeIndex,
        },
      }));
    });
  }

  const backgroundEl = document.querySelector('[data-background-slider]');
  if (backgroundEl) {
    const backgroundSlider = new Swiper(backgroundEl, {
      slidesPerView: 1,
      effect: 'fade',
      loop: true,
      rewind: false,
      speed: 1300,
      fadeEffect: {
        crossFade: true,
      },
    });
    backgroundSlider.controller.control = whiteboxSlider;
    document.addEventListener('setBackgroundSliderItem', e => backgroundSlider.slideTo(e.detail.number));
  }
});