import { onDomReady } from '../../components/dynamic/observer';

class BankModal {
  constructor(container) {
    this.container = container;
    this.link = this.container.querySelector('[data-bank-link]');
    this.modal = this.container.querySelector('[data-bank-modal]');
    this.bg = this.container.querySelector('[data-bank-modal-bg]');
    this.close = this.container.querySelector('[data-bank-modal-close]');

    if (this.modal) {
      this.eventlisteners();
    }
  }

  eventlisteners() {
    this.link.addEventListener('click', () => {
      this.modal.classList.add('_visible');
      document.body.classList.add('_hidden');
    });

    this.bg.addEventListener('click', () => {
      this.modal.classList.remove('_visible');
      document.body.classList.remove('_hidden');
    });

    this.close.addEventListener('click', () => {
      this.modal.classList.remove('_visible');
      document.body.classList.remove('_hidden');
    });
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-banks]').forEach((item) => {
    const bankModal = new BankModal(item);
  });
});