import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-page-slider-container]')) {
    return;
  }
  const pageSlider = new MyTinySlider({
    sliderContainer: '[data-page-slider-container]',
    sliderList: '[data-page-slider]',
    arrowLeft: '[data-page-left]',
    arrowRight: '[data-page-right]',
    slideIndex: '[data-page-slider-index]',
    slideCount: '[data-page-count]',
    slideBy: 1,
    items: 1,
    nav: false,
    speed: 700,
    center: true,
    controls: true,
    rewind: true,
    loop: false,
  });
});