import axios from 'axios';
import { onDomChanges } from '../../components/dynamic/observer';
import { singleBind } from '../../components/dynamic/bind';
import { notifyResponse } from '../notifications/notify';

async function dealToggle(linkElement) {
  let inDeal = Boolean(linkElement.dataset.active);

  const currentUrl = linkElement.getAttribute('href');
  const response = await axios.post(currentUrl);

  if (notifyResponse(response) && response.data.success) {
    inDeal = !inDeal;
    const addUrl = linkElement.dataset.add;
    const removeUrl = linkElement.dataset.remove;

    // Переключаем вкл/выкл
    linkElement.dataset.active = inDeal ? '1' : '';
    linkElement.setAttribute('href', inDeal ? removeUrl : addUrl);

    const event = new CustomEvent('dealToggle', {
      detail: {
        linkElement,
        inDeal,
      },
    });
    document.dispatchEvent(event);
  }
}

function initDeal() {
  document.querySelectorAll('[data-deal-toggle]').forEach((link) => {
    singleBind(link, 'bind', 'click', (e) => {
      e.preventDefault();
      dealToggle(link);
    });
  });
}

onDomChanges(initDeal);
initDeal();