class CustomSelect {
  static outsideReady = false;
  static entries = [];

  constructor(element) {
    element.dataset.initialized = 'true';
    CustomSelect.entries.push(this);
    this.isOpen = false;
    this.element = element;
    this.list = element.querySelector('[data-list]');
    this.opener = element.querySelector('[data-opener]');
    this.field = element.querySelector('[data-field]');
    this.replacedLabel = element.querySelector('[data-replaced-label]');
    this.openable = this.list && this.list.dataset.openable !== undefined && this.opener;
    this.inputs = Array.from(element.querySelectorAll('[data-input]'));
    this.filterable = this.field && this.field.dataset.filterable !== undefined;

    this.bind();
  }

  bind() {
    this.bindOpenable();
    this.bindInputs();
    this.bindFilterable();
    if (!CustomSelect.outsideReady) {
      CustomSelect.bindOutsideClick();
    }
    this.listeners();
  }

  listeners() {
    const instance = this;
    this.element.addEventListener('disable', e => instance.disable());
    this.element.addEventListener('enable', e => instance.enable());
  }

  /**
   * Биндим клик по документу
   * Хватаем раскрытые селекты
   * Проверяем, если клик за пределами селекта, то закрываем селект
   */
  static bindOutsideClick() {
    document.addEventListener('click', (e) => {
      const targetElement = e.target;
      CustomSelect.entries.filter(entity => entity.isOpen).forEach(entity => {
        if (entity.element !== targetElement && !entity.element.contains(targetElement)) {
          entity.close();
        }
      });
    });
  }

  /**
   * Биндим ввод в поле фильтрации вариантов в селекте
   * Приводим данные из поля ввода и варианты к нижнему регистру для сравнения между собой
   */
  bindFilterable() {
    if (this.filterable) {
      const instance = this;
      this.field.addEventListener('input', () => {
        const value = instance.field.value.trim().toLowerCase();
        instance.inputs.forEach((input) => {
          const parent = input.parentNode;
          const text = parent.querySelector('[data-label-title]').innerHTML.trim().toLowerCase();
          text.includes(value) ? parent.classList.remove('_hidden') : parent.classList.add('_hidden');
        });
      });

    }
  }

  /**
   * Биндим изменения инпутов
   * Закрываем выпадашку, если стоит data-auto-close="true"
   * Берем текст выбранного варианта и пихаем в лейбл или в поле см. setFieldValue()
   * Для множественного списка следим за пустым значением - либо оно либо что угодно другое, но не вместе
   */
  bindInputs() {
    const instance = this;
    this.inputs.forEach((input) => {
      input.addEventListener('change', () => {
        if (input.type === 'checkbox') {
          instance.checkBoxHandler(input);
        }
        if (instance.openable && input.dataset.autoClose !== undefined) {
          instance.close();
        }

        if (instance.field || instance.replacedLabel) {
          instance.setFieldValue();
        }
      });
    });
  }

  checkBoxHandler(changeInput) {
    const notValueInput = this.inputs.find(input => input.value === '0')
    if (!notValueInput) {
      return;
    }

    if (changeInput === notValueInput) {
      if (changeInput.checked) {
        this.inputs.filter(input => input !== notValueInput).forEach(input => input.checked = false);
      }

      return;
    }

    const checkedInputs = this.inputs.filter(input => input.checked === true && input.value !== '0');
    if (checkedInputs.length) {
      notValueInput.checked = false;
    } else {
      notValueInput.checked = true;
    }
  }


  /**
   * Формируем стрку из выбранного значения или значений и вставляем в лейбак или в поле
   * Если селект фильтрующийся - возвращаем отображение всех вариантов
   */
  setFieldValue() {
    let stringValue = '';
    this.inputs.filter(input => input.checked).forEach((input) => {
      const label = input.parentNode.querySelector('[data-label-title]');
      if (!label) return;
      const string = label.innerHTML.trim();
      stringValue += stringValue ? `, ${string}` : string;
    });

    if (this.field) {
      this.field.value = stringValue;
    }

    if (this.replacedLabel) {
      this.replacedLabel.innerHTML = stringValue;
    }

    if (this.filterable) {
      // this.inputs.forEach(input => input.parentNode.classList.remove('_hidden'));
    }
  }

  /**
   * Биндим открытие/закрытие
   */
  bindOpenable() {
    if (this.openable) {
      const instance = this;
      this.opener.addEventListener('click', (e) => {
        e.preventDefault();
        instance.isOpen ? instance.close() : instance.open();
      });
    }
  }

  close() {
    this.isOpen = false;
    this.element.classList.remove('_open');
  }

  open() {
    this.isOpen = true;
    this.element.classList.add('_open');
  }

  enable() {
    this.element.classList.remove('_disable');
  }

  disable() {
    this.close();
    this.element.classList.add('_disable');
  }
}
function initCustomSelects() {
  const fields = document.querySelectorAll('[data-custom-select]:not([data-initialized="true"])');
  fields.forEach(field => new CustomSelect(field));
}

document.addEventListener('DOMContentLoaded', initCustomSelects);
document.addEventListener('DOMContentMutated', initCustomSelects);