import { tns } from 'tiny-slider/src/tiny-slider';
import bindArrows from './common/bindArrows';
import updateFilling from './common/updateFilling';
import sliderCounter from './common/sliderCounter';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const heroSliderContainer = document.querySelector('[data-hero-project-slider-container]');
  const autoOff = document.querySelector('[data-auto-off]');

  if (heroSliderContainer) {
    const items = Array.from(heroSliderContainer.querySelectorAll('[data-hero-project-item]'));
    const textWrap = heroSliderContainer.querySelector('[data-hero-project-text]');
    const closeButton = heroSliderContainer.querySelector('[data-hero-project-close]');
    const mainHeroInfos = heroSliderContainer.querySelectorAll('[data-main-hero-info]');

    const heroSlider = tns({
      container: heroSliderContainer.querySelector('[data-hero-project-slider]'),
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 1500,
      controls: false,
      loop: false,
      mode: 'gallery',
      animateIn: 'plug-class-first',
      animateOut: 'plug-class-second',
      animateNormal: 'plug-class-normal',
      rewind: false,
      autoplayButtonOutput: false,
      preventActionWhenRunning: true,
    });

    if(!autoOff){
      setInterval(() => {
        const info = heroSlider.getInfo();
        if (info.index + 1 < info.slideCount) {
          document.querySelector('[data-hero-project-right]').click();
        }
      }, 9000);
    }
    
    bindArrows(
      '[data-hero-project-left]',
      '[data-hero-project-right]',
      heroSlider,
    );

    sliderCounter(
      heroSliderContainer,
      '[data-hero-project-slider-index]',
      '[data-hero-project-count]',
      heroSlider,
    );

    // heroSlider.events.on('indexChanged', () => {
    //   updateFilling(heroSlider.getInfo());
    // });
    // updateFilling(heroSlider.getInfo());

    const setActiveItem = (info) => {
      let currentItem;
      let prevIndex;
      let prevItem;

      items.forEach((item) => {
        if (item.classList.contains('_active')) {
          prevItem = item;
          prevIndex = parseInt(item.dataset.heroSlideNumber, 10);
          item.classList.remove('_active');
        }

        if (parseInt(item.dataset.heroSlideNumber, 10) === info.displayIndex) {
          item.classList.add('_active');
          currentItem = item;
          setMainHeroInfoActive(item);
        }

        if (parseInt(item.dataset.heroSlideNumber, 10) === prevIndex) {
          prevItem = item;
        }
      });

      if (info.displayIndex > prevIndex) {
        next(currentItem, prevItem);
      } else {
        prev(currentItem, prevItem);
      }

      if (info.displayIndex !== 1) {
        if (!textWrap.classList.contains('_close')) {
          textWrap.classList.add('_close');
        }
      } else if (textWrap.classList.contains('_close')) {
        textWrap.classList.remove('_close');
      }
    };

    const setMainHeroInfoActive = (activeSlide) => {
      mainHeroInfos.forEach((mainHeroInfo) => {
        if (mainHeroInfo.classList.contains('_active')) {
          mainHeroInfo.classList.remove('_active');
        }
        if (activeSlide.dataset.heroSlideNumber === mainHeroInfo.dataset.mainHeroInfo) {
          setTimeout(() => {
            mainHeroInfo.classList.add('_active');
          }, 800);
        }
      });
    };

    const next = (currentItem, prevItem) => {
      prevItem.classList.remove('_active', '_animate-in-next', '_animate-in-prev');
      prevItem.classList.add('_animate-out-next');
      currentItem.classList.remove('_animate-normal-next');
      currentItem.classList.add('_animate-in-next', '_active');

      setTimeout(() => {
        prevItem.classList.remove('_animate-out-next');
        prevItem.classList.add('_animate-normal-prev');
      }, 1500);
    };

    const prev = (currentItem, prevItem) => {
      prevItem.classList.remove('_active', '_animate-in-next', '_animate-in-prev');
      prevItem.classList.add('_animate-out-prev');
      currentItem.classList.remove('_animate-normal-prev');
      currentItem.classList.add('_animate-in-prev', '_active');

      setTimeout(() => {
        prevItem.classList.remove('_animate-out-prev');
        prevItem.classList.add('_animate-normal-next');
      }, 1500);
    };

    const addClasses = () => {
      items.forEach((item, index) => {
        if (index !== 0) {
          item.classList.remove('_animate-normal-prev');
          item.classList.add('_animate-normal-next');
        }
      });
    };

    if (heroSlider) {
      heroSlider.events.on('indexChanged', () => {
        updateFilling(heroSlider.getInfo());
      });
      updateFilling(heroSlider.getInfo());

      heroSlider.events.on('transitionStart', setActiveItem);

      if (closeButton) {
        closeButton.addEventListener('click', () => {
          heroSlider.goTo('first');
          addClasses();
        });
      }
    }
  }
});