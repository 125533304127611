import { onDomReady } from "../../components/dynamic/observer";

onDomReady(() => {
  const header = document.querySelector('[data-header]');
  if (!header) {
    return;
  }

  const onScroll = () => {
    if (window.pageYOffset >= 300) {
      header.classList.add('header_sticky');
    } else {
      header.classList.remove('header_sticky');
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();

  const nav = document.querySelector('[data-nav]');
  const navParents = document.querySelectorAll('[data-nav-parent]');

  if (navParents) {
    navParents.forEach((navParent) => {

      const enterHandler = () => {
        const leaverHandler = () => {
          nav.classList.remove('_active');
          navParent.addEventListener('mouseenter', enterHandler);
          navParent.removeEventListener('mouseleave', leaverHandler);
        };

        nav.classList.add('_active');
        navParent.addEventListener('mouseleave', leaverHandler);
        navParent.removeEventListener('mouseenter', enterHandler);

        navParents.forEach((item) => {
          item.style.zIndex = 0;
        });
        navParent.style.zIndex = 100;
      };

      navParent.addEventListener('mouseenter', enterHandler);
    });
  }
});