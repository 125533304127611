import { onDomReady } from '../../components/dynamic/observer';

class WindowSizeChecker {
  constructor() {
    this.init();
    this.setListeners();
  }

  init() {
    const targets = document.querySelectorAll('[data-model-link]:not([init="true"])');
    if (targets) {
      this.setTargetBlank(targets);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setTargetBlank(targets) {
    if (window.innerWidth > 1200) {
      targets.forEach((link) => {
        link.setAttribute('target', '_blank');
        link.setAttribute('init', 'true');
      });
    }
  }

  setListeners() {
    document.addEventListener('DOMContentMutated', () => {
      this.init();
    });
  }
}

onDomReady(() => {
  const sizeChecker = new WindowSizeChecker();
});