import { onDomReady } from '../../components/dynamic/observer';
import { tns } from 'tiny-slider/src/tiny-slider';
import bindArrows from './common/bindArrows';

onDomReady(() => {
  const imagesSliders = document.querySelectorAll('[data-layout-images-slider]');
  imagesSliders.forEach((imagesSlider) => {

    const slider = tns({
      container: imagesSlider,
      items: 1,
      slideBy: 1,
      arrowKeys: true,
      nav: false,
      speed: 500,
      controls: false,
      loop: false,
      mode: 'gallery',
      rewind: false,
      autoplayButtonOutput: false,
      preventActionWhenRunning: true,
    });

    bindArrows(
      '[data-prev-arrow]',
      '[data-next-arrow]',
      slider,
      imagesSlider.closest('[data-smart-content]'),
    );
  });
});