require('./page-slider');
require('./layout-ways-slider');
require('./layout-images-slider');
require('./layout-gallery-slider');
require('./project-hero-slider');
require('./advantage-slider');
require('./renovation-slider');
require('./catalog-advantage-slider');
require('./project-slider');
require('./switchable-slider');
require('./show-room-slider');
require('./project-renovations-slider-simple');
require('./reviews-slider');
require('./article-page-slider');
require('./project-renovations-slider-complicated');
require('./project-renovations-custom-slider');
require('./catalog-infra-slider');
// require('./layout-slider');
require('./layout-slider-swiper');
require('./project-construction-progress-slider');
require('./about-advantages-slider');
require('./about-our-values-slider');
require('./about-press-slider');
require('./about-history-slider');
require('./landing-slider')