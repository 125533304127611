import { tns } from 'tiny-slider/src/tiny-slider';
import Swiper, { Navigation } from 'swiper';
import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

Swiper.use([Navigation]);

onDomReady(() => {
  const mainWrap = document.querySelector('[data-about-history-slider-container]');

  if (!mainWrap) {
    return;
  }

  // Инит слайдеров внутри элементов списка
  mainWrap.querySelectorAll('[data-about-history-slider-secondary-container]')
    .forEach((item) => {
      if (item) {
        const secondarySlider = new MyTinySlider({
          sliderContainer: '[data-about-history-slider-secondary-container]',
          sliderList: '[data-about-history-slider-secondary]',
          arrowLeft: '[data-about-history-secondary-left]',
          arrowRight: '[data-about-history-secondary-right]',
          slideIndex: '[data-about-history-secondary-index]',
          slideCount: '[data-about-history-secondary-count]',
          slideBy: 1,
          items: 1,
          mode: 'gallery',
          animateIn: 'animate_in',
          animateOut: 'animate_out',
          animateNormal: 'normal',
          nav: false,
          speed: 1300,
          center: false,
          controls: false,
          rewind: true,
          loop: false,
          autoplayButtonOutput: false,
          preventActionWhenRunning: true,
        });
      }
    });

  const counter = new Swiper(document.querySelector('[data-about-history-slider]'), {
    modules: [Navigation],
    navigation: {
      prevEl: document.querySelector('[data-about-history-main-top]'),
      nextEl: document.querySelector('[data-about-history-main-bottom]'),
    },
    direction: 'vertical',
    centeredSlides: true,
    slidesPerView: 5,
    loop: true,
    rewind: false,
    speed: 1300,
    slideToClickedSlide: true,
  });

  // Главный слайдер
  const mainSlider = tns({
    container: mainWrap.querySelector('[data-about-history-slider-main]'),
    items: 1,
    slideBy: 1,
    nav: false,
    speed: 1300,
    controls: false,
    loop: true,
    mode: 'gallery',
    // animateIn: 'plug-class-first',
    // animateOut: 'plug-class-second',
    // animateNormal: 'plug-class-normal',
    rewind: false,
    autoplayButtonOutput: false,
    preventActionWhenRunning: true,
    onInit: () => {
      mainWrap.closest('#history').classList.remove('_active');
    },
  });

  counter.on('slideChange', () => {
    mainSlider.goTo(counter.realIndex);
  });
});