// Автоматически увеличиваем все гриды с квартирами на 1 на десктопе, если они изначально по 3
document.querySelectorAll('.seller-layout .catalog-grid._by-3').forEach((listElement) => {
  const setFullWidth = () => {
    if (window.innerWidth >= 1260) {
      listElement.classList.remove('_by-3');
      listElement.classList.add('_by-4');
    }
  };
  const setNarrowWidth = () => {
    listElement.classList.remove('_by-4');
    listElement.classList.add('_by-3');
  };

  if (window.sellerMenu) {
    if (window.sellerMenu.isMenuOpened()) {
      setNarrowWidth();
    }

    document.addEventListener('sellerMenuOpened', setNarrowWidth);
    document.addEventListener('sellerMenuClosed', setFullWidth);
  }
});