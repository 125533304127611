import Office from "./Office";
import defaultPlacementPath from '!file-loader!../../images/svg/office/placemark-selected.svg';

export default class ProjectOffice {
  constructor(element) {
    this.rootElement = element;
    if (!element) {
      return;
    }
    this.settings = Object.assign({}, element.dataset);
    this.contentElement = element.querySelector('[data-project-office-content]');
    this.mapElement = element.querySelector('[data-project-office-map]');
    this.toggleElements = element.querySelectorAll('[data-project-office-map]');
    this.offices = [];
    this.map = undefined;
    this.activeOffice = undefined;
    this.bind();
    this.initMapPending();
  }

  bind() {
    this.rootElement.querySelectorAll('[data-project-office-toggle]').forEach((toggler) => {
      toggler.addEventListener('click', () => {
        this.toggleOffice();
      });
    });
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  initMapPending() {
    if (window.ymaps) {
      this.initMap();
      return;
    }
    setTimeout(() => {
      this.initMapPending();
    }, 500);
  }

  initMap() {
    ymaps.ready(() => {
      this.initObjects();
      this.createMap();
    });
  }

  initObjects() {
    this.rootElement.querySelectorAll('[data-project-office-item]').forEach((itemElement) => {
      const office = new Office(
        itemElement,
      );
      const placemark = new ymaps.Placemark([office.getLat(), office.getLng()], {

      }, {
        iconLayout: 'default#imageWithContent',
        // Своё изображение иконки метки.
        iconImageHref: defaultPlacementPath,
        // Размеры метки.
        iconImageSize: [82, 82],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-41, -37],
        // Смещение слоя с содержимым относительно слоя с картинкой.
        iconContentOffset: [0, 0],
      });
      placemark.events.add('click', () => {
        this.onPlacemarkClick(office);
      });
      office.setPlacemark(placemark);
      this.offices.push(office);
    });
  }

  createMap() {
    const rect = this.mapElement.getBoundingClientRect();
    let bottom = 220;
    if (rect.height) {
      bottom = (rect.height/2 - 30);
    }

    const office = this.offices[0];

    this.map = new ymaps.Map(this.mapElement, {
      center: [office.getLat(), office.getLng()],
      zoom: office.getZoom(),
      controls: ['zoomControl'],
    }, {
      zoomControlPosition: { right: 10, top: 'auto', left: 'auto', bottom: bottom + 'px' },
      zoomControlSize: 'small',
    });
    this.map.behaviors.disable('scrollZoom');

    this.map.margin.setDefaultMargin(this.getMapMargin());

    this.offices.forEach((office) => {
      this.map.geoObjects.add(office.getPlacemark());
    });

    this.activateFirstOffice();
  }

  getMapMargin() {
    if (this.isMobile()) {
      return [31, 31, 31, 31];
    }
    const bounds = this.contentElement.getBoundingClientRect();
    return [62, 62, 62, bounds.right + 62];
  }

  activateFirstOffice() {
    this.activateOffice(this.offices[0]);
  }

  activateOffice(office) {
    this.offices.forEach((off) => {
      if (off !== office) {
        off.getPlacemark().options.set('visible', false);
        this.rootElement.classList.remove(`_active-${off.getType()}`);
      }
    });
    office.getPlacemark().options.set('visible', true);
    this.rootElement.classList.add(`_active-${office.getType()}`);
    this.map.panTo(
      [office.getLat(), office.getLng()], {
        duration: 1500,
        flying: true,
        useMapMargin: true,
      },
    );
    this.activeOffice = office;
  }

  onPlacemarkClick() {

  }

  toggleOffice() {
    const nextOffice = this.offices.find((office) => {
      return this.activeOffice !== office;
    });
    this.activateOffice(nextOffice);
  }
}