import { onDomReady } from '../../components/dynamic/observer';

const init = () => {
  if (window.innerWidth > 767) {
    const items = document.querySelectorAll('[data-gen-infra]');
    const button = document.querySelector('[data-gen-infra-off]');

    if (button) {
      button.addEventListener('click', () => {
        button.classList.toggle('_off');
        items.forEach((item) => {
          item.classList.toggle('_hidden');
        });
      });
    }
  }
};

onDomReady(() => init());