const updateFilling = (info) => {
  const slidesIndex = info.displayIndex;
  const slidesCount = info.slideCount;
  const containerOuter = info.container.closest('[data-line-container]');
  const strip = containerOuter.querySelector('[data-line]');
  if (strip) {
    const stripWidth = strip.offsetWidth;
    const stripFilling = strip.querySelector('[data-line-filling]');
    stripFilling.style.width = `${stripWidth * slidesIndex / slidesCount}px`;
  }
};

export default updateFilling;