import { onDomReady } from '../../components/dynamic/observer';

class StickyMobFilter {
  constructor(selector) {
    this.menu = selector;

    this.lastUpdatedPositionsAt = undefined;

    const rootStyles = getComputedStyle(document.querySelector('.layout'));
    this.menuTranslate = rootStyles.getPropertyValue('--menu-translate').slice(0, -2);

    this.eventListeners();
  }

  eventListeners() {
    document.addEventListener('scroll', () => {
      if (window.pageYOffset >= 300) {
        this.menu.classList.add('_sticky');
      } else {
        this.menu.classList.remove('_sticky');
      }
    });
  }
}


const init = () => {
  const filterButton = document.querySelector('[data-filter-button-sticky]');
  if (filterButton) {
    const filterStickyButton = new StickyMobFilter(filterButton);
  }
};

onDomReady(() => init());