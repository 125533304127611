import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-renovation-slider-container]')) {
    return;
  }
  const renovationSlider = new MyTinySlider({
    sliderContainer: '[data-renovation-slider-container]',
    sliderList: '[data-renovation-slider]',
    arrowLeft: '[data-renovation-left]',
    arrowRight: '[data-renovation-right]',
    slideIndex: '[data-renovation-slider-index]',
    slideCount: '[data-renovation-count]',
    slideBy: 1,
    nav: true,
    navPosition: 'bottom',
    speed: 700,
    center: true,
    controls: false,
    rewind: true,
    loop: false,
    responsive: {
      0: {
        gutter: 12,
        fixedWidth: 221,
      },
      768: {
        gutter: 24,
        fixedWidth: 333,
      },
      1280: {
        gutter: 28,
        fixedWidth: 380,
      },
    },
  });
});