import axios from 'axios';
import LiveEvent from '../../components/live/live';
import { notifyResponse } from '../notifications/notify';

new LiveEvent('click', '[data-embed-delete]', async function (e) {
  e.preventDefault();

  const response = await axios.post(this.getAttribute('href'), {});
  if (notifyResponse(response)) {
    window.location.reload();
  }
});

new LiveEvent('click', '[data-embed-update]', async function (e) {
  e.preventDefault();
  const response = await axios.post(this.getAttribute('href'), {});
  if (notifyResponse(response)) {
    window.location.reload();
  }
});