import Jax from '../../components/jax/jax';
import _ from 'lodash';
import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class Filter {
  constructor(form) {
    this.form = form;
    this.top = form.querySelector('[data-filter-top]');
    this.opener = form.querySelector('[data-filter-opener]');
    this.mobileOpenerButtons = document.querySelectorAll('[data-filter-opener-mob]');
    this.closer = form.querySelector('[data-filter-closer]');
    this.buttonSubmit = form.querySelector('[data-submit-button]');
    this.buttonReset = form.querySelector('[data-button-reset]');
    this.replaceOnMobile = false;
    this.changeViewLinks = Array.from(form.querySelectorAll('[data-change-view]'));
    this.lastSearchButton = form.querySelector('[data-last-search-button]');
    this.breackPoints = {
      medium: 768,
      large: 1260,
    };

    this.debounce = _.debounce(() => this.request(this.form), 1000);
    this.init();
  }

  init() {
    this.initInputs();
    this.bind();
  }

  initInputs() {
    this.checkedInputs = Array.from(this.form.querySelectorAll('input[type="checkbox"], input[type="radio"]'));
    this.rangeInputs = this.form.querySelectorAll('.range-input input');
    this.bindChecked();
    this.bindRanges();
    this.bindChains();
  }

  bindChains() {
    const projectInputs = Array.from(this.form.querySelectorAll('[data-field-project] input[type="checkbox"]'));
    const addressInputs = Array.from(this.form.querySelectorAll('[data-field-address] input[type="checkbox"]'));

    if (projectInputs.length && addressInputs.length) {
      projectInputs.forEach((projectInput) => {
        projectInput.addEventListener('change', (e) => {
          const projectIds = [];
          const zeroInput = addressInputs.find(addressInput => addressInput.value === '0');
          zeroInput.checked = true;
          zeroInput.dispatchEvent(new Event('change'));

          projectInputs
            .filter(checkedInput => checkedInput.checked && checkedInput.value !== '0')
            .forEach(checkedInput => projectIds.push(parseInt(checkedInput.value, 10)));

          addressInputs.forEach((addressInput) => {
            const buildingProjectId = parseInt(addressInput.dataset.projectId, 10);
            if (projectIds.length === 0 || !buildingProjectId || projectIds.includes(buildingProjectId)) {
              addressInput.parentNode.classList.remove('_hidden');
            } else {
              addressInput.parentNode.classList.add('_hidden');
            }
          });
        });
      });

      const sectionField = this.form.querySelector('[data-field-section]');
      if (sectionField) {
        const sectionInputs = Array.from(sectionField.querySelectorAll('input[type="checkbox"]'));

        const sectionIds = new Set();
        addressInputs.forEach((addressInput) => {
          addressInput.addEventListener('change', (e) => {
            sectionInputs.forEach((sectionInput) => {
              sectionInput.checked = false;
              sectionInput.dispatchEvent(new Event('change'));
            });
            sectionIds.clear();

            addressInputs
              .filter(checkedInput => checkedInput.checked && checkedInput.value !== '0')
              .forEach((checkedInput) => {
                const numberArray = checkedInput.dataset.sections.split(',').map(Number);
                numberArray.forEach(number => sectionIds.add(number));
              });

            if (sectionIds.size > 0) {
              sectionField.classList.add('_visible');
              sectionInputs.forEach((sectionInput) => {
                if (!sectionIds.has(parseInt(sectionInput.value, 10))) {
                  sectionInput.parentNode.classList.add('_hidden');
                } else {
                  sectionInput.parentNode.classList.remove('_hidden');
                }
              });
            } else {
              sectionField.classList.remove('_visible');
              sectionInputs.map(sectionInput => sectionInput.parentNode.classList.remove('_hidden'));
            }
          });
        });
      }
    }
  }

  bind() {
    this.bindOpener();
    this.bindCloser();
    this.bindSubmit();
    this.bindButtons();
    this.bindMobOpeners();
  }

  lastSearchRequest() {
    if (this.lastSearchButton) {
      this.lastSearchButton.remove();
    }
    const instance = this;
    const action = `${this.form.getAttribute('action')}?last_layout_search=true`;
    const jax = new Jax(action, this.form.getAttribute('method'));
    jax.send(new FormData(this.form)).then((html) => {
      instance.responseHandler(html);
      instance.replaceFields(html);
      instance.initInputs();
    });
  }

  replaceFields(html) {
    const page = document.createElement('div');
    page.innerHTML = html;

    const newTopFields = page.querySelector('[data-top-fields]');
    const currentTopFields = this.form.querySelector('[data-top-fields]');
    if (newTopFields && currentTopFields) {
      currentTopFields.innerHTML = newTopFields.innerHTML;
    }

    const newBottomFields = page.querySelector('[data-bottom-fields]');
    const currentBottomFields = this.form.querySelector('[data-bottom-fields]');
    if (newBottomFields && currentBottomFields) {
      currentBottomFields.innerHTML = newBottomFields.innerHTML;
    }

    const newTags = page.querySelector('[data-tags]');
    const currentTags = this.form.querySelector('[data-tags]');
    if (newTags && currentTags) {
      currentTags.innerHTML = newTags.innerHTML;
    }

    document.dispatchEvent(new Event('DOMContentMutated'));
    document.dispatchEvent(new Event('CatalogReplaced'));
  }

  request(form) {
    const instance = this;
    if (this.lastSearchButton) {
      this.lastSearchButton.remove();
    }
    const jax = new Jax(form.getAttribute('action'), form.getAttribute('method'));
    jax.send(new FormData(form)).then(html => instance.responseHandler(html));
  }

  responseHandler(html) {
    const instance = this;
    const page = document.createElement('div');
    page.innerHTML = html;

    instance.replaceTotals(page);
    if (window.innerWidth >= this.breackPoints.medium) {
      instance.replaceCatalogContent(page);
    } else if (instance.replaceOnMobile) {
      instance.replaceCatalogContent(page);
      instance.replaceOnMobile = false;
    }

    document.dispatchEvent(new Event('DOMContentMutated'));
    document.dispatchEvent(new Event('CatalogReplaced'));
  }

  replaceTotals(page) {
    const totalCount = page.querySelector('[data-total-count]');
    const totalText = page.querySelector('[data-total-text]');
    if (totalCount) {
      this.form.querySelectorAll('[data-total-count]')
        .forEach(element => element.innerHTML = totalCount.innerHTML);
    }
    if (totalText) {
      this.form.querySelectorAll('[data-total-text]')
        .forEach((element) => {
          element.innerHTML = totalText.innerHTML;
        });
    }
  }

  replaceCatalogContent(page) {
    const newData = page.querySelector('[data-catalog-content]');
    const currentData = document.querySelector('[data-catalog-content]');
    if (newData && currentData) {
      currentData.innerHTML = newData.innerHTML;
    }
  }

  bindButtons() {
    const instance = this;
    if (this.buttonSubmit) {
      this.buttonSubmit.addEventListener('click', (e) => {
        e.preventDefault();
        this.replaceOnMobile = true;
        instance.request(this.form);
        instance.closeFilter();
      });
    }

    if (this.buttonReset) {
      this.buttonReset.addEventListener('click', (e) => {
        e.preventDefault();
        instance.resetRadioInputs();
        instance.resetCheckboxInputs();
        instance.resetRangeInputs();
      });
    }

    if (this.lastSearchButton) {
      this.lastSearchButton.addEventListener('click', (e) => {
        e.preventDefault();
        instance.lastSearchButton.remove();
        instance.lastSearchRequest();
      });
    }

    const catalogBlock = document.querySelector('[data-catalog-content]');

    if (this.changeViewLinks && catalogBlock) {
      this.changeViewLinks.forEach((link) => {
        const modeClass = `_view-mode_${link.dataset.changeView}`;

        link.addEventListener('click', (e) => {
          e.preventDefault();
          const oldLink = this.changeViewLinks.find(item => item !== link);
          if (oldLink) {
            const oldmodeClass = `_view-mode_${oldLink.dataset.changeView}`;
            catalogBlock.classList.remove(oldmodeClass);
            catalogBlock.classList.add(modeClass);
            link.classList.add('_active');
            oldLink.classList.remove('_active');
          }
        });
      });
    }
  }

  bindRanges() {
    const instance = this;
    this.rangeInputs.forEach((input) => {
      input.addEventListener('change', instance.debounce);
    });
  }

  bindChecked() {
    const instance = this;
    this.checkedInputs.forEach((input) => {
      input.addEventListener('change', () => {
        instance.debounce();
        if (input.closest('[data-bottom-fields]')) {
          this.replaceOnMobile = true;
        }
      });
    });
  }

  bindSubmit() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.request(instance.form);
    });
  }

  bindCloser() {
    if (this.closer) {
      this.closer.addEventListener('click', (e) =>  {
        e.preventDefault();
        this.closeFilter();
      });
    }
  }

  bindOpener() {
    if (this.opener) {
      this.opener.addEventListener('click', (e) => {
        e.preventDefault();
        this.openFilter();
      });
    }
  }

  bindMobOpeners() {
    if (this.mobileOpenerButtons) {
      this.mobileOpenerButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
          e.preventDefault();
          this.openFilter();
        });
      });
    }
  }

  openFilter() {
    this.form.classList.add('_open');
    let event = new Event('DOMContentMutated');
    document.dispatchEvent(event);
  }

  closeFilter() {
    this.form.classList.remove('_open');
  }

  getRadioInputs() {
    return this.checkedInputs.filter(input => input.type === 'radio');
  }

  getCheckboxInputs() {
    return this.checkedInputs.filter(input => input.type === 'checkbox');
  }

  resetRadioInputs() {
    this.getRadioInputs()
      .filter(input => input.value === '0')
      .forEach((input) => {
        input.checked = 'checked';
        input.dispatchEvent(new Event('change'));
      });
  }

  resetCheckboxInputs() {
    this.getCheckboxInputs().forEach((input) => {
      input.checked = input.value === '0' ? 'checked' : '';
      input.dispatchEvent(new Event('change'));
    });
  }

  resetRangeInputs() {
    this.rangeInputs.forEach((input) => {
      const parent = input.closest('[data-range-input]');
      if (parent) {
        if (input.dataset.rangeFromInput !== undefined) {
          input.value = parent.dataset.min;
        } else if (input.dataset.rangeToInput !== undefined) {
          input.value = parent.dataset.max;
        }
      }
      input.dispatchEvent(new Event('hardSet'));
    });
  }

  findSectionInAddresses(addressInputs, projectIds, sectionIds) {
    addressInputs.forEach((checkedInput) => {
      const buildingProjectId = parseInt(checkedInput.dataset.projectId, 10);
      if (projectIds.length === 0 || !buildingProjectId || projectIds.includes(buildingProjectId)) {
        if (checkedInput.value !== '0') {
          const numberArray = checkedInput.dataset.sections.split(',').map(Number);
          numberArray.forEach(number => sectionIds.add(number));
        }
      }
    });
  }
}

const init = () => {
  const form = document.querySelector('[data-layout-filter]:not([data-initialized])');
  if (form) {
    form.dataset.initialized = "true";
    new Filter(form);
  }
};
onDomReady(() => init());
onDomChanges(() => init());