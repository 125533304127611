document.querySelectorAll('[data-print-link]').forEach((printLink) => {
  printLink.addEventListener('click', () => {
    if (document.querySelector('[data-seller-print]')) {
      const priceBlock = document.querySelector('[data-renovation-price]');
      // eslint-disable-next-line no-alert
      if (window.confirm('Показывать цену с отделкой?')) {
        priceBlock.classList.remove('_hide');
      } else {
        priceBlock.classList.add('_hide');
      }
    }

    window.print();
  });
});