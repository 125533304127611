import { onDomReady } from '../../components/dynamic/observer';

class ProjectRenovationsCustomSlider {
  constructor(selector) {
    this.slider = selector;
    this.items = Array.from(this.slider.querySelectorAll('[data-project-renovations-custom-slider-item]'));

    this.eventListeners();
  }

  eventListeners() {
    this.items.forEach((item) => {
      item.addEventListener('click', () => {
        this.removeClasses();
        if (!item.classList.contains('_active')) {
          item.classList.add('_active');
        }
      });
    });
  }

  removeClasses() {
    this.items.forEach((item) => {
      if (item.classList.contains('_active')) {
        item.classList.remove('_active');

        item.classList.add('_close');
        setTimeout(() => {
          item.classList.remove('_close');
        }, 800);
      }
    });
  }
}

const init = () => {
  const elem = document.querySelector('[data-project-renovations-custom-slider]');
  if (elem) {
    const projectRenovationsCustomSlider = new ProjectRenovationsCustomSlider(elem);
  }
};

onDomReady(() => init());