/*
Итак, что нам нужно предусмотреть:
- Удаление элемента в любой момент
- Смещение Вперед/Назад
- Разное количество отображаемых элементов на разных экранах
- Скрывать блоки, которые содержат одинаковые значения элементов по запросу

Сводим всё это в функцию render, которая приводит в соответствие параметры списка/внешней среды
к элементам физически

Событие (что-то произошло: смещение вперед, назад, изменение размеров экрана, удаление элемента)
---- Далее всегда идет
Вызов функции рендера
---- Далее всегда идет
Приведении в соответствие DOM-дерева текущему состоянию объекта / внешний среды
*/

import CompareBlock from './CompareBlock';

export default class CompareList {
  constructor(element, options) {
    this.rootElement = element;
    this.nextElement = element.querySelector('[data-customer-compare-next]');
    this.prevElement = element.querySelector('[data-customer-compare-prev]');
    this.diffElement = element.querySelector('[data-compare-differences]');
    this.diffElement = element.querySelector('[data-compare-differences]');

    this.differencesOnly = this.diffElement.checked;
    this.blocks = [];
    this.initBlocks();
    this.quantity = this.blocks[0].countElements();
    this.firstElement = 0;
    this.by = options.by;

    this.bind();
    this.render();
  }

  bind() {
    this.nextElement.addEventListener('click', () => {
      this.goNext();
    });
    this.prevElement.addEventListener('click', () => {
      this.goPrev();
    });
    this.diffElement.addEventListener('change', () => {
      this.differencesOnly = this.diffElement.checked;
      this.render();
    });
  }

  initBlocks() {
    this.rootElement.querySelectorAll('[data-compare-block]').forEach((blockElement) => {
      this.blocks.push(
        new CompareBlock(blockElement),
      );
    });
  }

  getFirstVisibleElement() {
    return this.firstElement;
  }

  getLastVisibleElement() {
    return this.firstElement + this.by() - 1;
  }

  isFirstVisibleElement() {
    return this.getFirstVisibleElement() === 0;
  }

  isLastVisibleElement() {
    return this.getLastVisibleElement() === (this.countElements() - 1);
  }

  countElements() {
    return this.blocks[0].countElements();
  }

  getElementsOfFirstBlock() {
    return this.blocks[0].getElements();
  }

  getVisibleElementsIndexes() {
    const visibleElements = [];
    let currentIndex = this.getFirstVisibleElement();
    while (currentIndex <= this.getLastVisibleElement()) {
      visibleElements.push(currentIndex);
      currentIndex += 1;
    }
    return visibleElements;
  }

  goNext() {
    this.firstElement += 1;
    this.render();
  }

  goPrev() {
    this.firstElement -= 1;
    this.render();
  }

  normalizeFirstElement() {
    if (this.firstElement + this.by() >= this.countElements()) {
      this.firstElement = this.countElements() - this.by();
    }
    if (this.firstElement < 0) {
      this.firstElement = 0;
    }
  }

  deleteElementByKey(key) {
    this.blocks.forEach((compareBlock) => {
      compareBlock.deleteElementByKey(key);
    });
    this.render();
  }

  render() {
    this.normalizeFirstElement();
    this.blocks.forEach((compareBlock) => {
      compareBlock.setVisibleElementsByIndexes(this.getVisibleElementsIndexes());
      if (this.differencesOnly) {
        compareBlock.hideByDifferences();
      } else {
        compareBlock.show();
      }
    });

    if (this.isFirstVisibleElement()) {
      this.prevElement.classList.add('_inactive');
    } else {
      this.prevElement.classList.remove('_inactive');
    }

    if (this.isLastVisibleElement()) {
      this.nextElement.classList.add('_inactive');
    } else {
      this.nextElement.classList.remove('_inactive');
    }
  }
}