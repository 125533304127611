import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-about-press-container]')) {
    return;
  }
  const reviewsSlider = new MyTinySlider({
    sliderContainer: '[data-about-press-container]',
    sliderList: '[data-about-press-slider]',
    arrowLeft: '[data-about-press-left]',
    arrowRight: '[data-about-press-right]',
    slideIndex: '[data-about-press-index]',
    slideCount: '[data-about-press-count]',
    slideBy: 1,
    items: 3,
    nav: false,
    speed: 700,
    center: false,
    controls: true,
    rewind: false,
    loop: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        navPosition: 'bottom',
      },
      768: {
        fixedWidth: 333,
        gutter: 24,
        items: 2,
        nav: false,
      },
      1280: {
        fixedWidth: 380,
        gutter: 28,
        items: 3,
        nav: false,
      },
    },
  });
});