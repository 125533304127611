import State from '../common/state';
import Jax from '../../components/jax/jax';

const STATE_KEY = 'SELLER_MENU_OPENED';

class SellerMenu {
  constructor(rootElement) {
    this.rootElement = rootElement;
    this.layoutElement = document.querySelector('[data-layout]');
    this.substrateElement = document.querySelector('[data-menu-substrate]');
    this.setDefaultState();
    this.bind();
  }

  setDefaultState() {
    if (State.get(STATE_KEY, true) && window.innerWidth >= 1260) {
      this.openMenu();
    }
  }

  bind() {
    this.layoutElement.querySelectorAll('[data-menu-toggle]').forEach((el) => {
      el.addEventListener('click', () => {
        this.toggleMenu();
      });
    });

    this.layoutElement.querySelectorAll('[data-close-client]').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        this.requestAndRefresh(el);
      });
    });

    this.rootElement.querySelectorAll('[data-current-project-menu-toggle]').forEach((el) => {
      el.addEventListener('click', () => {
        this.toggleProjects();
      });
    });

    this.rootElement.querySelectorAll('[data-settings-toggle]').forEach((el) => {
      el.addEventListener('click', () => {
        this.toggleSettings();
      });
    });

    this.layoutElement.querySelectorAll('[data-menu-substrate]').forEach((el) => {
      el.addEventListener('click', () => {
        this.toggleMenu();
      });
    });
  }

  toggleMenu() {
    if (this.layoutElement.classList.contains('_menu-opened')) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  isMenuOpened() {
    return this.layoutElement.classList.contains('_menu-opened');
  }

  openMenu() {
    this.layoutElement.classList.add('_menu-opened');
    State.set(STATE_KEY, true);

    const event = new CustomEvent('sellerMenuOpened');
    document.dispatchEvent(event);
  }

  closeMenu() {
    this.layoutElement.classList.remove('_menu-opened');
    setTimeout(() => {
      this.closeProjects();
    }, 500);
    State.set(STATE_KEY, false);

    const event = new CustomEvent('sellerMenuClosed');
    document.dispatchEvent(event);
  }

  toggleProjects() {
    this.rootElement.classList.toggle('_projects-opened');
    if (this.rootElement.classList.contains('_projects-opened')) {
      this.openMenu();
    }
  }

  closeProjects() {
    this.rootElement.classList.remove('_projects-opened');
  }

  toggleSettings() {
    this.rootElement.classList.toggle('_settings-opened');
  }

  async requestAndRefresh(link) {
    const url = link.getAttribute('href');
    const jax = new Jax(url);
    await jax.send();
    document.location.reload();
  }
}

const rootElement = document.querySelector('[data-seller-menu]');
if (rootElement) {
  window.sellerMenu = new SellerMenu(rootElement);
}