import _ from 'lodash';
import Jax from '../../components/jax/jax';

class CustomerSearch {
  constructor(rootElement) {
    this.rootElement = rootElement;
    this.inputElement = rootElement.querySelector('[data-customer-search-input]');
    this.filterElements = rootElement.querySelectorAll('[data-customer-filter-input]');
    this.contentSelector = '[data-customer-search-content]';
    this.bind();
    this.inputElement.focus();
  }

  bind() {
    this.rootElement.addEventListener('submit', (e) => {
      e.preventDefault();
      this.search();
    });
    this.inputElement.addEventListener('input', _.debounce(() => {
      this.search();
    }, 200));
    this.filterElements.forEach((filterElement) => {
      filterElement.addEventListener('change', _.debounce(() => {
        this.search();
      }, 200));
    });
    document.documentElement.addEventListener('click', (e) => {
      if (e.target
          && !e.target.closest('[data-customer-search-content]')
          && !e.target.closest('[data-customer-search]')
      ) {
        this.setUnfocus();
      } else {
        this.removeUnfocus();
      }
    });

    document.addEventListener('table:switcher-toggle', (e) => {
      this.search();
    });
  }

  setUnfocus() {
    document.querySelector(this.contentSelector).classList.add('_unfocus');
  }

  removeUnfocus() {
    document.querySelector(this.contentSelector).classList.remove('_unfocus');
  }

  async search() {
    const url = this.rootElement.getAttribute('action');
    const jax = new Jax(url);
    const html = await jax.send(new FormData(this.rootElement));

    const page = document.createElement('div');
    page.innerHTML = html;
    document.querySelector(this.contentSelector)
      .replaceWith(page.querySelector(this.contentSelector));
    document.querySelector(this.contentSelector).classList.add('_active');

    const event = new Event('DOMContentMutated');
    document.dispatchEvent(event);
  }
}

const rootElement = document.querySelector('[data-customer-search]');
if (rootElement) {
  new CustomerSearch(rootElement);
}