import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-reviews-slider-container]')) {
    return;
  }
  const reviewsSlider = new MyTinySlider({
    sliderContainer: '[data-reviews-slider-container]',
    sliderList: '[data-reviews-slider]',
    arrowLeft: '[data-reviews-left]',
    arrowRight: '[data-reviews-right]',
    slideIndex: '[data-reviews-slider-index]',
    slideCount: '[data-reviews-count]',
    slideBy: 1,
    items: 1,
    mode: 'gallery',
    animateIn: 'fade-in',
    animateOut: 'fade-out',
    animateNormal: 'normal',
    nav: false,
    speed: 700,
    center: true,
    controls: true,
    rewind: true,
    loop: true,
  });
});