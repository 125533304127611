import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const textWrap = document.querySelector('[data-about-our-values-slider-text-container]');
  const imagesWrap = document.querySelector('[data-about-our-values-slider-images-container]');

  if (!textWrap && !imagesWrap) {
    return;
  }

  const textSlider = new MyTinySlider({
    sliderContainer: '[data-about-our-values-slider-text-container]',
    sliderList: '[data-about-our-values-slider-text]',
    // arrowLeft: '[data-about-our-values-left]',
    // arrowRight: '[data-about-our-values-right]',
    // slideIndex: '[data-about-our-values-slider-index]',
    // slideCount: '[data-about-our-values-count]',
    slideBy: 1,
    items: 1,
    nav: false,
    speed: 1300,
    mode: 'gallery',
    animateIn: 'animate_in',
    animateOut: 'animate_out',
    animateNormal: 'normal',
    center: false,
    controls: false,
    rewind: true,
    loop: false,
    preventActionWhenRunning: true,
  });

  const imagesSlider = new MyTinySlider({
    sliderContainer: '[data-about-our-values-slider-images-container]',
    sliderList: '[data-about-our-values-slider-images]',
    arrowLeft: '[data-about-our-values-left]',
    arrowRight: '[data-about-our-values-right]',
    slideIndex: '[data-about-our-values-slider-index]',
    slideCount: '[data-about-our-values-count]',
    slideBy: 1,
    items: 1,
    nav: false,
    speed: 1300,
    mode: 'gallery',
    animateIn: 'animate_in',
    animateOut: 'animate_out',
    animateNormal: 'normal',
    center: false,
    controls: false,
    rewind: true,
    loop: false,
    preventActionWhenRunning: true,
  });

  const syncSliders = function (info) {
    if (info.container.hasAttribute('data-about-our-values-slider-images')
        && !info.container.hasAttribute('data-blocked')) {
      info.container.setAttribute('data-blocked', '');
      textSlider.slider.goTo(info.index);
      info.container.removeAttribute('data-blocked');
    } else if (info.container.hasAttribute('data-about-our-values-slider-text')
      && !info.container.hasAttribute('data-blocked')) {
      info.container.setAttribute('data-blocked', '');
      imagesSlider.slider.goTo(info.index);
      info.container.removeAttribute('data-blocked');
    }
  };
// console.log(imagesSlider);
  imagesSlider.slider.events.on('transitionStart', syncSliders);
  textSlider.slider.events.on('transitionStart', syncSliders);
});