// Как писать шаблоны смотрим тут: https://lodash.com/docs/4.17.15#template
// Шаблон обертки над отдельным сообщением
export default `
  <div class="notification__wrapper">
    <div class="container container_spacer">
      <div class="notification__line">
        <div class="notification__container" data-container></div>
        <% if (closable) { %>
            <a href="javascript:void(0);" class="notification__close" data-close></a>
        <% } %>
      </div>
    </div>
  </div>
`;