class ScrollUp {
  constructor(selector) {
    this.element = selector;

    this.eventListeners();
  }

  eventListeners() {
    document.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop === 0) {
        this.element.classList.add('_hidden');
      } else {
        this.element.classList.remove('_hidden');
      }
    });
  }
}

const init = () => {
  const items = Array.from(document.querySelectorAll('[data-scroll-up]'));
  if (items) {
    items.forEach((item) => {
      const scrollUp = new ScrollUp(item);
    });
  }
};

document.addEventListener('DOMContentLoaded', init);