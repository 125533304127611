import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-project-slider-container]')) {
    return;
  }
  const projectSlider = new MyTinySlider({
    sliderContainer: '[data-project-slider-container]',
    sliderList: '[data-project-slider]',
    arrowLeft: '[data-project-left]',
    arrowRight: '[data-project-right]',
    slideIndex: '[data-project-slider-index]',
    slideCount: '[data-project-count]',
    slideBy: 1,
    items: 1,
    nav: false,
    speed: 1300,
    mode: 'gallery',
    animateIn: 'animate_in',
    animateOut: 'animate_out',
    animateNormal: 'normal',
    center: false,
    controls: false,
    rewind: true,
    loop: false,
    preventActionWhenRunning: true,
  });
});